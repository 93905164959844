import { SET_USER_TRACKING_DIALOG_OPEN, SET_USER_TRACKING_STATUS } from '@/common/user-tracking/user-tracking-actions'
import { USER_TRACKING_KEY, userTrackingState } from '@/common/user-tracking/user-tracking-constants'

export const userTrackingReducer = (state = null, action = {}) => {
  // define default state if not present
  //  (can't do it in signature as property shouldOpen depends on property trackingState)
  if (state === null) {
    const trackingState = localStorage?.getItem(USER_TRACKING_KEY)
    state = {
      trackingState,
      shouldOpen: trackingState === userTrackingState.NOT_DEFINED,
    }
  }

  switch (action.type) {
    case SET_USER_TRACKING_STATUS:
      if (action.payload.trackingState === userTrackingState.NOT_DEFINED) {
        localStorage.removeItem(USER_TRACKING_KEY)
      } else {
        localStorage.setItem(USER_TRACKING_KEY, action.payload.trackingState)
      }
      return { ...state, trackingState: action.payload.trackingState }
    case SET_USER_TRACKING_DIALOG_OPEN:
      return { ...state, shouldOpen: action.payload.shouldOpen }
    default:
      return state
  }
}
