import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'

const getChain = state => state.entries.chain
export const getChainEntries = state => getEntries(state, 'entries.chain')
export const isChainLoaded = state => getChain(state).loaded
export const getChainByChannelDetails = (state, moduleTypeNumber, moduleSerialNumber, channelIndex) => {
  const slot = `channels/${parseInt(channelIndex, 10) + 1}`
  return getChainEntries(state).find(
    chain =>
      chain.channel?.typeNumber === moduleTypeNumber &&
      chain.channel?.serialNumber === moduleSerialNumber &&
      chain.channel?.slot === slot,
  )
}
export const getChainDuplicatesByChannel = (state, moduleTypeNumber, moduleSerialNumber, channelIndex) => {
  const slot = `channels/${parseInt(channelIndex, 10) + 1}`
  const channelChain = getChainEntries(state).filter(
    chain =>
      chain.channel?.typeNumber === moduleTypeNumber &&
      chain.channel?.serialNumber === moduleSerialNumber &&
      chain.channel?.slot === slot,
  )
  return channelChain.slice(1)
}

// cache this function so that we will always get the same result by reference if the state didn't change
let dependencySource
let cachedResult = {}
export const filterChainsBelongingToModule = (chainEntries, moduleTypeNumber, moduleSerialNumber) => {
  if (dependencySource !== chainEntries) {
    dependencySource = chainEntries
    cachedResult = {}
  }

  const argumentId = JSON.stringify({ moduleTypeNumber, moduleSerialNumber })
  if (!cachedResult[argumentId]) {
    cachedResult[argumentId] = chainEntries.filter(
      chain => chain.channel?.typeNumber === moduleTypeNumber && chain.channel?.serialNumber === moduleSerialNumber,
    )
  }
  return cachedResult[argumentId]
}

export const getChainsByModuleDetails = (state, moduleTypeNumber, moduleSerialNumber) => {
  const chainEntries = getChainEntries(state)

  return filterChainsBelongingToModule(chainEntries, moduleTypeNumber, moduleSerialNumber)
}

export const getChannelsChainItemFromAllChainItems = (projectDevice, moduleIndex, channelIndex, chainItems) => {
  const currentModule = projectDevice.modules[moduleIndex]
  const typeNumber = currentModule?.getType()
  const serialNumber = currentModule?.parametersReadable?.snr || projectDevice.serialNumber
  return chainItems.find(
    item =>
      item.channel?.typeNumber === typeNumber &&
      item.channel?.serialNumber === serialNumber &&
      item.channel?.slot === `channels/${channelIndex + 1}`,
  )
}
