import { OAuth } from 'skybase-oauth/oauth'
import { areSkydaqAPISDisabled } from '@/utils'

export const eulaSelector = state => ({
  ...state.eula,
})

export const getIsEulaUsed = () => {
  return (
    OAuth.isInNormalMode &&
    !areSkydaqAPISDisabled() &&
    OAuth.config.USE_EULA &&
    !['false', '0'].includes(OAuth.config.USE_EULA)
  )
}
