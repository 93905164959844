import React, { PureComponent } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { curry, escapeRegExp, uniqBy } from 'lodash'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { expandedRowsArrowPositions, expandedRowsTypes } from 'skybase-ui/skybase-components/sb-data-table/constants'
import { hintPositions, horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown'
import { openConfirmModal } from 'skybase-oauth/actions'
import { CopyBox } from 'skybase-oauth/common/components/copy-box'
import { closeModal, openModal } from 'skybase-ui/skybase-core/base/actions'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { showErrorToast, showInfoToast, showSuccessToast } from '@/common/services/show-toast'
import { GREEN, RED, DARK, StatusBullet } from '@/common/status-bullet'
import { messages as a } from 'skybase-oauth/messages-i18n'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { getOAuthState } from 'skybase-oauth/utils'
import { getStudioAPIHost } from '@/utils/url'
import { getDropdownLabelByValue, getValue } from '@/utils'
import { CertificateIcon } from '@/fleet-configuration/components/certificate-icon/certificate-icon'
import { CalibrationBox } from '@/fleet-configuration/components/calibration/calibration-box/calibration-box'
import { findComponentByTypeAndSerial } from '@/fleet-configuration/utils/device-utils'
import {
  CDN_SERVICE_100,
  EQUIPMENT_MODAL_ID,
  equipmentApi,
  families,
  formScenarios,
  SHOW_EVERYTHING_FILTER_VALUE,
} from '../constants'
import { equipmentShape, calibrationShape } from '../shapes'
import { getEquipmentIconClassNameByFamily } from '../utils'
import { getLastFilledPageNumberIfPageIsEmpty } from '@/common/data-table/utils'
import { messages as t } from '../equipment-i18n'
import './equipment.scss'

class _Equipment extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    loading: PropTypes.bool.isRequired,
    handleOpenConfirmModal: PropTypes.func.isRequired,
    handleEditEquipmentModal: PropTypes.func.isRequired,
    handleOnCloseModal: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(equipmentShape),
    components: PropTypes.arrayOf(
      PropTypes.shape({ kistlerType: PropTypes.string, deviceDocUrl: PropTypes.string, icon: PropTypes.string }),
    ),
    calibrations: PropTypes.arrayOf(calibrationShape),
    onExpand: PropTypes.func.isRequired,
    expandedRow: PropTypes.string,
    userId: PropTypes.string,
    tenantId: PropTypes.string,
  }

  static defaultProps = {
    data: [],
    components: [],
    calibrations: [],
    expandedRow: null,
    userId: '',
    tenantId: '',
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = [
      {
        name: 'family',
        label: _(t.family),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      {
        name: 'serialNumber',
        label: _(t.serialNumber),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      {
        name: 'typeNumber',
        label: _(t.typeNumber),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      {
        name: 'typeName',
        label: _(t.name),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      {
        name: 'userNotes',
        label: _(t.notes),
        cellsClassName: 'text-align-left sb-width-m',
        headerClassName: 'text-align-left sb-width-m',
      },
      { name: '_actions', label: ' ', sortable: false },
    ]
    this.state = {
      pagination: {
        pageSize: 50,
        pageNumber: 1,
      },
      filter: { family: SHOW_EVERYTHING_FILTER_VALUE },
    }
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  handleOnFilterChange = (key, value) => {
    const { filter } = this.state
    this.setState({ filter: { ...filter, [key]: value } })
  }

  onRowExpanded = id => {
    const { onExpand, expandedRow } = this.props

    onExpand(expandedRow !== id ? id : null)
  }

  renderActions = row => {
    const {
      intl: { formatMessage: _ },
      components,
      calibrations,
    } = this.props
    const { id, typeNumber, family } = row
    const { deviceDocUrl } = findComponentByTypeAndSerial(components, typeNumber)
    const { notifications = 0 } = findComponentByTypeAndSerial(calibrations, row.typeNumber, row.serialNumber)

    const chainIcon = deviceDocUrl ? (
      <SbHint position={hintPositions.topCenter} hintData={_(t.documentation)}>
        <a
          href={deviceDocUrl}
          aria-label={_(t.documentation)}
          target="_blank"
          className="icon-link open-documentation"
          rel="noreferrer"
        >
          <i className="sbi-link-kids" />
        </a>
      </SbHint>
    ) : (
      <SbHint
        position={hintPositions.topCenter}
        hintData={typeNumber ? _(t.documentationOfNotAvailable, { typeNumber }) : _(t.documentationNotAvailable)}
      >
        <span className="sbi-link-kids icon-link icon-disabled open-documentation" />
      </SbHint>
    )

    const deleteIcon = (
      <SbHint position={hintPositions.topCenter} hintData={_(t.deleteEquipment)}>
        <span className="sbi-delete-kids icon-link delete-equipment" onClick={() => this.openConfirmModal(id)} />
      </SbHint>
    )

    /* Calibration notification should be displayed only for sensors or kidaq devices (DHUB-4485) */
    const notificationsNode =
      notifications > 0 && (family === families.SENSOR || typeNumber.startsWith('55')) ? (
        <SbHint
          className="num-of-notification"
          hintData={<>{_(t.notificationsIssueSToPayAttention, { notifications })}</>}
        >
          <span>{notifications}</span>
        </SbHint>
      ) : null

    const editIcon = (
      <SbHint position={hintPositions.topCenter} hintData={_(t.editEquipment)}>
        <span className="sbi-pencil icon-link" onClick={() => this.openEditEquipmentModal(row)} />
      </SbHint>
    )

    return (
      <div className="actions-wrapper">
        {notificationsNode}
        <div onClick={evt => evt.stopPropagation()} className="show-on-hover">
          {deleteIcon}
          {editIcon}
          {chainIcon}
          {family === families.SENSOR || typeNumber.startsWith('55') ? (
            <CertificateIcon calibrations={calibrations} row={row} />
          ) : null}
        </div>
      </div>
    )
  }

  openConfirmModal = id => {
    const {
      handleOnCloseModal,
      handleOpenConfirmModal,
      refetch,
      data,
      intl: { formatMessage: _ },
    } = this.props
    const {
      pagination: { pageNumber, pageSize },
    } = this.state

    handleOpenConfirmModal({
      title: _(t.deleteEquipment),
      message: (
        <>
          <div>{_(t.deleteDesc)}</div>
          <div>{_(t.deleteDesc2)}</div>
        </>
      ),
      confirmButtonTitle: _(t.delete),
      confirmButtonClassName: 'destructive',
      handleOnConfirm: () => {
        axios
          .delete(`${getStudioAPIHost()}${equipmentApi.EQUIPMENT}/${id}`)
          .then(() => {
            showSuccessToast(_(t.equipmentWasSuccessfullyDeleted), _(a.success))

            // Go to previous page when deleting the last item in the list
            if ((data || []).length % pageSize === 1 && pageNumber > 1) {
              this.setState(({ pagination }) => ({
                pagination: { ...pagination, pageNumber: pagination.pageNumber - 1 },
              }))
            }

            refetch()
          })
          .catch(error => {
            showErrorToast(
              _(t.failedToDeleteAnEquipment, { errorMessage: error?.errors?.message ?? 'unknown.' }),
              _(a.error),
            )
          })
        handleOnCloseModal()
      },
    })
  }

  openEditEquipmentModal = equipmentData => {
    const { handleEditEquipmentModal } = this.props
    handleEditEquipmentModal(equipmentData)
  }

  handleOnAfterCopy = (evt, value) => {
    evt.stopPropagation()
    const {
      intl: { formatMessage: _ },
    } = this.props
    showInfoToast(_(t.copiedText, { text: value }))
  }

  renderFamilyCell = value => {
    const {
      intl: { formatMessage: _ },
    } = this.props
    const iconClassName = getEquipmentIconClassNameByFamily(value)
    if (!iconClassName) {
      return '-'
    }
    return (
      <div className="fl-row fl-align-items-center">
        <span className={classNames(iconClassName, 'family-icon')} />
        {_(t[value] || t.unknown)}
      </div>
    )
  }

  renderSerialNumberCell = value => (value ? <CopyBox onAfterCopy={this.handleOnAfterCopy} text={value} /> : '-')

  renderTypeNumberCell = value => (value ? <CopyBox onAfterCopy={this.handleOnAfterCopy} text={value} /> : '-')

  renderUserNotesCell = value => <div className="sb-truncate sb-width-m">{value || '-'}</div>

  cellFormatter = (value, key, row) => {
    if (key === 'family') {
      return this.renderFamilyCell(value)
    }

    if (key === 'serialNumber') {
      return this.renderSerialNumberCell(value)
    }

    if (key === 'typeNumber') {
      return this.renderTypeNumberCell(value)
    }

    if (key === 'userNotes') {
      return this.renderUserNotesCell(value)
    }
    if (key === '_actions') {
      return this.renderActions(row)
    }

    return getValue(value)
  }

  filterCellFormatter = curry((filter, columnName) => {
    const {
      data,
      intl: { formatMessage: _ },
    } = this.props
    if (['serialNumber', 'typeNumber', 'typeName', 'userNotes'].includes(columnName)) {
      return (
        <SbTextbox
          value={filter[columnName] || ''}
          onChange={evt => this.handleOnFilterChange(columnName, evt.target.value)}
        />
      )
    }
    if (['family'].includes(columnName)) {
      const items = [{ value: SHOW_EVERYTHING_FILTER_VALUE, label: _(t.all) }].concat(
        uniqBy(data, columnName).map(item => ({
          value: item[columnName],
          label: _(t[item[columnName]] || t.unknown),
        })),
      )
      return (
        <SbDropdown
          items={items}
          value={filter[columnName] || ''}
          title={getDropdownLabelByValue(items, filter[columnName] || '')}
          onChange={value => this.handleOnFilterChange(columnName, value)}
        />
      )
    }
    return null
  }, 2)

  rowFormatter = row => {
    const { expandedRow } = this.props

    return {
      className: row.id === expandedRow ? 'opened-expanded-row' : '',
    }
  }

  showImageIfAvailable = icon => {
    //don't show image for default values
    if (icon === '' || icon === undefined) {
      return ''
    }

    const url = CDN_SERVICE_100 + icon
    return (
      <div className="device-image-wrapper">
        <img alt="" src={url} />
      </div>
    )
  }

  expandableRowFormatter = row => {
    const {
      expandedRow,
      calibrations,
      intl: { formatDate, formatMessage: _, locale },
      components,
      userId,
      tenantId,
    } = this.props
    const { typeNumber } = row
    const { icon } = components?.find(c => c.kistlerType?.toLowerCase() === typeNumber?.toLowerCase()) || {}

    if (expandedRow === row.id) {
      let calibrationContent
      if (!row.typeNumber || !row.serialNumber) {
        // requests without typeNumber or serialNumber are not allowed.
        calibrationContent = <StatusBullet status={DARK}>{_(t.noCalibrationData)}</StatusBullet>
      } else {
        const deviceCalibrationData = findComponentByTypeAndSerial(calibrations, row.typeNumber, row.serialNumber)
        const hasRequestError = deviceCalibrationData.hasRequestError
        const calibrationDate = new Date(deviceCalibrationData.calibrationDate)
        const calibrationExpires = new Date(calibrationDate)
        calibrationExpires.setDate(calibrationDate.getDate() + deviceCalibrationData.calibrationIntervalDays)
        const expirationWarning = deviceCalibrationData.isExpiring ? (
          <SbHint hintData={<>{_(t.calibrationOfYourEquipmentExpiredOrIsAboutToExpire, { newLine: <br /> })}</>}>
            <span className="sbi-toast-warning warning" />
          </SbHint>
        ) : null

        if (hasRequestError) {
          calibrationContent = (
            <div className="fl-row fl-align-items-center">
              <SbHint
                hintData={<>{_(t.errorCalibrationData, { newLine: <br /> })}</>}
                position={{ horizontal: horizontalPosition.CENTER, vertical: verticalPosition.TOP }}
                wrapperClassName="calibration-date"
              >
                <span className="sbi-toast-error color-error" />
              </SbHint>
              <div>{_(t.notAvailable)}</div>
            </div>
          )
        } else if (!deviceCalibrationData.isLoading) {
          calibrationContent =
            isNaN(calibrationExpires) || !calibrationDate.getTime() ? (
              <StatusBullet status={DARK}>{_(t.noCalibrationData)}</StatusBullet>
            ) : (
              <StatusBullet alignCenter={false} status={deviceCalibrationData.isExpiring ? RED : GREEN}>
                <div>
                  <div className="fl-container fl-justify-sb m-b-5">
                    <span>
                      {_(t.expiration)}
                      <span className="m-l-5">{expirationWarning}</span>
                    </span>
                    <span>{formatDate(calibrationExpires, { format: 'date-table' })}</span>
                  </div>
                  <div className="fl-container fl-justify-sb m-b-5">
                    <span>{_(t.lastCalibration)}</span>
                    <span>{formatDate(calibrationDate, { format: 'date-table' })}</span>
                  </div>
                  <div className="fl-container fl-justify-sb color-black-25">
                    <span>{_(t.statusAsOf)}</span>
                    <span>
                      {formatDate(deviceCalibrationData.cacheEntryUpdatedTimestamp, { format: 'date-table' })}
                    </span>
                  </div>
                </div>
              </StatusBullet>
            )
        } else {
          calibrationContent = (
            <StatusBullet status={DARK}>
              {_(t.expiry, {
                date: <SbLoader show />,
              })}
            </StatusBullet>
          )
        }
      }

      const languageOfLocale = locale.match(/^[a-z]+/i)?.[0].toLowerCase()
      return (
        <div className="equipment-expanded-row">
          {this.showImageIfAvailable(icon)}
          {
            /* Calibration information should be displayed only for sensors or kidaq devices (DHUB-4485) */
            row.family === families.SENSOR || row.typeNumber.startsWith('55') ? (
              <CalibrationBox
                languageOfLocale={languageOfLocale}
                calibrationContent={calibrationContent}
                serialNumber={row.serialNumber}
                typeNumber={row.typeNumber}
                userId={userId}
                tenantId={tenantId}
              />
            ) : null
          }
          <div className="user-notes-expanded">
            <h2 className="calibration-heading">{_(t.notes)}</h2>
            {row.userNotes || '-'}
          </div>
        </div>
      )
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  applyFilterOnSourceData = sourceData => {
    const { filter } = this.state
    return sourceData.filter(row => {
      if (
        ['name', 'serialNumber', 'typeNumber', 'typeName', 'userNotes'].some(
          field => filter[field] && !new RegExp(escapeRegExp(filter[field]), 'i').test(row[field]),
        )
      ) {
        return false
      }

      if (['family'].some(field => ![undefined, SHOW_EVERYTHING_FILTER_VALUE, row[field]].includes(filter[field]))) {
        return false
      }

      return true
    })
  }

  render() {
    const { pagination } = this.state
    const {
      data,
      intl: { formatMessage: _ },
      loading,
    } = this.props
    const { filter } = this.state
    const filteredData = this.applyFilterOnSourceData(data)
    const lastPageNumber = getLastFilledPageNumberIfPageIsEmpty(filteredData, pagination)
    if (lastPageNumber !== null) {
      this.setState({ pagination: { ...pagination, pageNumber: lastPageNumber } })
    }

    return (
      <SbDataTable
        id="equipment-table"
        className="list-table"
        rowsUniqueKeyName="id"
        columns={this.tableColumns}
        loading={loading}
        data={filteredData}
        rowFormatter={row => this.rowFormatter(row)}
        cellFormatter={this.cellFormatter}
        enableFilterRow
        filterCellFormatter={this.filterCellFormatter(filter)}
        emptyMessage={_(t.noEquipmentFound)}
        enablePagination
        asyncData={false}
        paginationProps={{
          pageSizeDropdownProps: {
            className: 'min-width-100px',
          },
          ...pagination,
          onChange: this.handleOnPaginationChange,
        }}
        defaultSortBy={{
          sortCol: 'family',
          sortOrder: 'asc',
        }}
        expandableRowsType={expandedRowsTypes.SINGLE}
        expandableRowsArrowPosition={expandedRowsArrowPositions.LEFT}
        onExpandableRowToggle={this.onRowExpanded}
        expandableRowFormatter={this.expandableRowFormatter}
      />
    )
  }
}

const mapStateToProps = state => {
  const { id: userId, tenant: tenantId } = getOAuthState(state)[AUTH_STATE_KEY_NAME]

  return {
    userId,
    tenantId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleEditEquipmentModal: equipmentData =>
      dispatch(openModal(EQUIPMENT_MODAL_ID, { data: equipmentData, scenario: formScenarios.UPDATE })),
    handleOpenConfirmModal: config => dispatch(openConfirmModal(config)),
    handleOnCloseModal: () => dispatch(closeModal()),
  }
}

export const Equipment = injectIntl(connect(mapStateToProps, mapDispatchToProps)(_Equipment))
