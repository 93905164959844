import { combineReducers } from 'redux'

import { devicesReducer } from './components/devices/devices-reducer'
import { DEVICES_STATE_KEY_NAME } from './components/devices/constants'
import { dataSourcesReducer } from './components/devices/datasources/datasources-reducer'
import { DEVICE_DATA_SOURCES_KEY_NAME } from './components/devices/datasources/constants'

import { notificationsReducer } from './components/notifications/notifications-reducer'
import { NOTIFICATIONS_STATE_KEY_NAME } from './components/notifications/constants'

export const iotHubReducers = combineReducers({
  [DEVICES_STATE_KEY_NAME]: devicesReducer,
  [DEVICE_DATA_SOURCES_KEY_NAME]: dataSourcesReducer,
  [NOTIFICATIONS_STATE_KEY_NAME]: notificationsReducer,
})
