import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'
import { DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-constants'
import { intersection } from 'lodash'
import { NOT_CALIBRABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'

export const getDeviceCalibrations = state => getEntries(state, 'entries.devicesCalibrations')

export const getDeviceCalibrationsByDeviceId = (state, deviceId) => state.entries.devicesCalibrations.byId[deviceId]

export const getIfCalibrationIsLoadedOrExpiring = calibration => {
  let isExpiring = false
  if (calibration) {
    const calibrationExpires = new Date(calibration.calibrationDate)
    calibrationExpires.setDate(calibrationExpires.getDate() + calibration.calibrationIntervalDays)
    const expirationWarningDate = new Date()
    expirationWarningDate.setDate(expirationWarningDate.getDate() + DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS)
    isExpiring = expirationWarningDate.getTime() > calibrationExpires.getTime() && calibration.calibrationDate
  }
  return { isExpiring, isLoaded: calibration && !calibration.hasRequestError && !calibration.isLoading }
}

export const getExtendedDeviceCalibrationByDeviceId = (state, deviceId) => {
  const deviceCalibrations = getDeviceCalibrationsByDeviceId(state, deviceId)
  const device = getDeviceById(state, deviceId)
  const isLoadedOrExpiring = getIfCalibrationIsLoadedOrExpiring(deviceCalibrations)
  // Ignore KGATE_DEVICE_TYPE and FORCE_PLATE_SYNC_BOX types as they are not calibrable
  const isDeviceCalibrable = device && !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length

  return {
    ...deviceCalibrations,
    ...isLoadedOrExpiring,
    isCalibrable: isDeviceCalibrable,
  }
}
