// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import update from 'immutability-helper'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { uniqBy } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { objectDiffDeep } from '@/utils/diff'
import {
  removeProjectDevice,
  updateProjectDeviceById,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { clearProjectDeviceDirty } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-actions'
import { removeDeviceLastState } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-actions'
import { addOnlineStatusToDeviceModel } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-utils'
import { getStudioAPIHost } from '@/utils/url'
import { DEVICES_LOADED_EVENT, SET_DEVICE_EVENT } from '@/fleet-configuration/data-fleet/devices/devices-constants'
import {
  handleDefaultHttpError,
  handleHttpError,
  hasRequestTimedOut,
} from '@/fleet-configuration/utils/handleHttpError'
import { areDevicesLoaded, getDeviceById, getDevices } from './devices-selectors'
import { messages as t } from './devices-actions-i18n'
import { subscribeToDevicesStatusWS } from '@/iot-hub/components/devices/utils'
import { setInitialDevicesOnlineStatus } from '@/iot-hub/components/devices/actions'
import { fixateAllTypesInDevices } from '@/fleet-configuration/utils/fixate-all-types-in-devices'
import { removeComponentActiveProject } from '@/fleet-configuration/data-fleet/components/components-actions'
import { getProjectComponentsByFamily } from '@/fleet-configuration/data-fleet/components/components-selectors'

export const REMOVE_ALL_DEVICES = getActionName('REMOVE_ALL_DEVICES')
export const removeAllDevices = () => createAction(REMOVE_ALL_DEVICES)

export const REMOVE_DEVICE_ENTRY = getActionName('REMOVE_DEVICE_ENTRY')
export const removeDeviceEntry = device => createAction(REMOVE_DEVICE_ENTRY, device)

export const SET_DEVICE = getActionName('SET_DEVICE')
export const setDevice = device => createAction(SET_DEVICE, device)

export const DEVICES_LOADED = getActionName('DEVICES_LOADED')
export const devicesLoaded = () => createAction(DEVICES_LOADED)

export const setDeviceName = (deviceId, name) => (dispatch, getState) => {
  const device = getDeviceById(getState(), deviceId)
  if (!device) {
    console.error('updating nonexistend device ', deviceId)
    return false
  }
  const updatedDevice = update(device, { name: { $set: name } })
  dispatch(setDevice(updatedDevice))
  SbEmitter.emit(SET_DEVICE_EVENT, device)
  return updatedDevice
}

export const updateDeviceById = (deviceId, updateSpec) => (dispatch, getState) => {
  const state = getState()
  const device = getDeviceById(state, deviceId)
  if (!device) {
    return null
  }
  const updatedDevice = update(device, updateSpec)
  if (!Object.keys(objectDiffDeep(device, updatedDevice)).length) {
    return null
  }
  const result = dispatch(setDevice(updatedDevice))
  SbEmitter.emit(SET_DEVICE_EVENT, updatedDevice)
  return result
}

let loadDevicesCancelToken
export const tryCancelLoadDevicesRequest = () => {
  if (loadDevicesCancelToken) {
    loadDevicesCancelToken('Obsolete device data canceled')
    return true
  }
  return false
}

const handleLoadDevicesData = (data, dispatch) => {
  loadDevicesCancelToken = null
  const uniqueDevices = fixateAllTypesInDevices(uniqBy(data, 'id'))
  const fullDevices = uniqueDevices.map(device => addOnlineStatusToDeviceModel(device.status, device))

  if (uniqueDevices.length !== data.length) {
    console.warn('Duplicated devices: ', data)
  }

  const actions = [
    setInitialDevicesOnlineStatus(uniqueDevices),
    removeAllDevices(),
    ...fullDevices.map(device => setDevice(device)),
    devicesLoaded(),
  ]
  fullDevices.forEach(device =>
    dispatch(
      updateProjectDeviceById(device.id, {
        online: { $set: device.online },
        status: { $set: device.status },
      }),
    ),
  )
  dispatch(batchActions(actions))
  SbEmitter.emit(DEVICES_LOADED_EVENT, { devices: fullDevices })
  return fullDevices
}

const getLoadDevicesPromise = config =>
  axios.get(`${getStudioAPIHost()}/api/devices`, {
    timeout: 20000,
    cancelToken: new axios.CancelToken(canceler => {
      loadDevicesCancelToken = canceler
    }),
    ...config,
  })

export const loadDevices = () => async dispatch => {
  const { data } = await getLoadDevicesPromise({
    customErrorHandler: error =>
      // Ignore timeouts for the non-graceful device loads (e.g. triggered internally by UI)
      hasRequestTimedOut(error) ? null : handleDefaultHttpError(error),
  })
  subscribeToDevicesStatusWS()
  return handleLoadDevicesData(data, dispatch)
}

export const loadDevicesGracefully = () => async dispatch => {
  try {
    const { data } = await getLoadDevicesPromise({})
    return handleLoadDevicesData(data, dispatch)
  } catch (error) {
    console.error(error)
    return []
  }
}

export const loadDevicesIfEmpty = () => (dispatch, getState) =>
  areDevicesLoaded(getState()) ? getDevices(getState()) : dispatch(loadDevices())

export const identifyDevice = deviceId => () => {
  return axios.post(`${getStudioAPIHost()}/api/devices/${deviceId}/identify`, null, {
    customErrorHandler: error => {
      console.error(error)
      handleHttpError(t.identificationOfDeviceFailed)
    },
  })
}

export const factoryResetDevice = deviceId => async (dispatch, getState) => {
  dispatch(removeDeviceEntry({ id: deviceId }))
  const result = axios.post(`${getStudioAPIHost()}/api/devices/${deviceId}/factoryReset`, null, {
    customErrorHandler: error => {
      console.error(error)
      handleHttpError(t.factoryResetOfDeviceFailed, t.unableToTriggerFactoryResetOnTheDevice)
    },
  })
  // temporary fix: remove device from project.
  //  When it's added back, it will have correct default configuration and no dirty flags
  //  this temporary fix will be removed once following issues are realized: DHUB-843, DHUB-428, DHUB-504 and DHUB-505
  const deviceComponent = getProjectComponentsByFamily(getState(), 'device')?.find(
    component => component.deviceId === deviceId,
  )
  if (deviceComponent) {
    await dispatch(removeComponentActiveProject(deviceComponent))
    dispatch(removeProjectDevice(deviceId))
    dispatch(clearProjectDeviceDirty(deviceId))
    dispatch(removeDeviceLastState(deviceId))
  }

  return result
}

export const rebootDevice = deviceId => () => {
  return axios.post(`${getStudioAPIHost()}/api/devices/${deviceId}/reboot`, null, {
    customErrorHandler: error => {
      console.error('Reboot of device failed', error)
      handleHttpError(t.deviceRebootFailed, t.unableToTriggerRebootOnTheDevice)
    },
  })
}

export const storeDeviceName = (deviceId, deviceName) => () => {
  tryCancelLoadDevicesRequest()
  return axios.post(
    `${getStudioAPIHost()}/api/devices/${deviceId}/name`,
    { deviceName },
    {
      customErrorHandler: error => {
        console.error('Store of device name failed', error)
      },
    },
  )
}
