import { v4 } from 'uuid'
import ReconnectingWebSocket from 'reconnecting-websocket'
import axios from 'axios'
import uniq from 'lodash/uniq'
import { batchActions } from 'redux-batched-actions'

import { OAuth } from 'skybase-oauth'
import { messages as oa } from 'skybase-oauth/messages-i18n'
import { showErrorToastFactory, showSuccessToastFactory } from 'skybase-oauth/actions'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { STATES } from 'skybase-oauth/constants'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { WSClients } from '@/common/websocket/ws-clients'
import { devicesStatusListener } from '@/iot-hub/components/devices/devices-status-listener'
import { iotHubPermissions } from '@/common/permissions'
import { showSuccessToast } from '@/common/services/show-toast'
import { addRunningMeasurement, removeRunningMeasurement } from '@/iot-hub/components/devices/datasources/actions'
import { RED, GREEN, ORANGE, DARK } from '@/common/status-bullet'
import { getColorsObjectFromArray, replaceDuplicateSlashes, searchIn, areSkydaqAPISDisabled } from '@/utils'
import {
  getDevicesApi,
  getDeviceApi,
  getDeviceByIpApi,
  getDeviceResourceApi,
  setDeviceResourceApi,
  getDeviceDataSourcesApi,
  getDeviceDataSourceApi,
  setDeviceDataSourceApi,
  getDeviceDataSourceConfigurationApi,
  setDeviceDataSourceConfigurationApi,
  getDeviceDataSourceTransferConfigurationApi,
  startDeviceDataSourceApi,
  stopDeviceDataSourceApi,
  requestStreamingAuthInfoApi,
} from '@/iot-hub/rest'
import {
  changeListDevicesState,
  setDevices,
  removeDevice,
  loadDeviceToUpdate,
  changeUpdateDeviceState,
  changeDeviceResourceState,
  loadDeviceResourceToUpdate,
  loadDeviceResourceInterfaceToUpdate,
  changeDeviceResourceInterfaceRetrieveState,
  changeDeviceResourceInterfaceUpdateState,
  setDeviceResourceInterfaceErrors,
  addDevice,
  setInitialDevicesOnlineStatus,
} from './actions'
import {
  setDeviceDataSources,
  loadDeviceDataSourceToUpdate,
  changeDeviceDataSourcesState,
  changeListDeviceDataSourcesState,
  changeUpdateDeviceDataSourceState,
  loadDeviceDataSourceConfigurationToUpdate,
  changeRetrieveDeviceDataSourceConfigurationState,
  changeUpdateDeviceDataSourceConfigurationState,
  setDeviceDataTransferConfiguration,
  changeDeviceDataSourceTransferConfigurationState,
  setDeviceDataTransferActiveTransferMethod,
  changeDataDataSourceTransferState,
  setDeviceDataSourceConfigurationErrors,
} from './datasources/actions'
import {
  resourceStructures,
  deviceStatuses,
  cloudProvisioningStatuses,
  ownershipStatuses,
  OIC_WK_MNT,
  X_COM_KISTLER_KICONNECT_SHUTDOWN,
  DEVICE_REGISTER_LISTENER_NAME,
  deviceStatusWebsocketKey,
} from './constants'
import { dataSourceStates, supportedTransferMethods, certificateTypes } from './datasources/constants'
import { messages as t } from './devices-i18n'
import { getStudioAPIHost } from '@/utils/url'
import { batchLogMessages } from '@/common/console-logging/console-logging'

const { NORMAL, LOADING } = STATES
const { FLAT } = resourceStructures
const { OFFLINE } = deviceStatuses
const { STARTED, STOPPED } = dataSourceStates
// const { KISTLER_STREAM_V2 } = encodings
const { READYTOREGISTER, REGISTERING, REGISTERED, FAILED, UNINITIALIZED, UNKNOWN } = cloudProvisioningStatuses
const { READY_TO_BE_OWNED, OWNED, OWNED_BY_OTHER } = ownershipStatuses

// Basic error handling, shows all errors as toasts.
export const handleErrors = ({ errors }, dispatch) => {
  errors.forEach(({ message }) => showErrorToastFactory({ title: oa.error, message })(dispatch))
}

// Filter for removing an empty device, caused by the current deployment of BE
export const filterEmptyDevices = dev =>
  !(
    dev.status === OFFLINE &&
    dev?.resources?.length === 1 &&
    dev?.resources?.[0]?.types?.[0] === 'x.cloud.device.status'
  )

export const fetchDevices = () => async dispatch => {
  try {
    dispatch(changeListDevicesState(LOADING))
    const devices = await getDevicesApi()
    const filteredDevices = devices.filter(filterEmptyDevices)

    dispatch(
      batchActions([
        setInitialDevicesOnlineStatus(devices),
        changeListDevicesState(NORMAL),
        setDevices(filteredDevices),
      ]),
    )

    return filteredDevices
  } catch ({ errors }) {
    dispatch(changeListDevicesState(NORMAL))

    if (errors) {
      handleErrors({ errors }, dispatch)
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDevicesFetch }))
    }

    return []
  }
}

export const fetchDevice =
  (deviceId, resourceStructure = FLAT, onSuccess = () => {}, onError = () => {}) =>
  async dispatch => {
    try {
      dispatch(changeUpdateDeviceState(LOADING))
      const device = await getDeviceApi(deviceId, resourceStructure)
      const { resources, ...deviceWithoutResources } = device

      dispatch(
        batchActions([changeUpdateDeviceState(NORMAL), addDevice(deviceWithoutResources), loadDeviceToUpdate(device)]),
      )

      if (onSuccess) {
        onSuccess(device)
      }
    } catch ({ errors }) {
      dispatch(changeUpdateDeviceState(NORMAL))

      if (errors) {
        handleErrors({ errors }, dispatch)
      } else {
        dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDevicesFetch }))
      }

      if (onError) {
        onError()
      }
    }
  }

export const fetchDeviceByIp = ipAddress => async dispatch => {
  try {
    const devices = await getDeviceByIpApi(ipAddress)
    const device = { ...devices[0], ipAddress }
    const { resources, ...deviceWithoutResources } = device

    dispatch(batchActions([addDevice(deviceWithoutResources), loadDeviceToUpdate(device), closeModal()]))

    showSuccessToast(
      {
        message: t.deviceWithIpWasAddedToTheList,
        params: { ipAddress },
      },
      oa.success,
    )

    // Makes the pagination jump to the page where this device is placed in the list.
    SbEmitter.emit('deviceJumpToPage', null, device.id)
  } catch (error) {
    const { errors } = error

    if (errors) {
      handleErrors({ errors }, dispatch)
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDevicesFetch }))
    }

    throw error
  }
}

export const fetchResource = resourcePath => async dispatch => {
  try {
    dispatch(changeDeviceResourceState(LOADING))
    const resource = await getDeviceResourceApi({ resourcePath })

    dispatch(
      batchActions([
        changeDeviceResourceState(NORMAL),
        loadDeviceResourceToUpdate({
          resourceData: resource,
          resourceInterface: resource,
          resourcePath: replaceDuplicateSlashes(`/${resourcePath}`),
        }),
      ]),
    )
  } catch ({ errors }) {
    dispatch(changeDeviceResourceState(NORMAL))

    if (errors) {
      handleErrors({ errors }, dispatch)
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceResourceFetch }))
    }
  }
}

export const fetchResourceInterface =
  ({ resourcePath, resourceInterface }) =>
  async dispatch => {
    try {
      dispatch(changeDeviceResourceInterfaceRetrieveState(LOADING))
      const resource = await getDeviceResourceApi({ resourcePath, resourceInterface })

      dispatch(
        batchActions([
          changeDeviceResourceInterfaceRetrieveState(NORMAL),
          loadDeviceResourceInterfaceToUpdate(resource),
        ]),
      )
    } catch ({ errors }) {
      dispatch(changeDeviceResourceInterfaceRetrieveState(NORMAL))

      if (errors) {
        handleErrors({ errors }, dispatch)
      } else {
        dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceResourceFetch }))
      }
    }
  }

export const updateResourceInterface =
  ({ resourcePath, resourceInterface, data, types }) =>
  async dispatch => {
    try {
      dispatch(changeDeviceResourceInterfaceUpdateState(LOADING))
      const resource = await setDeviceResourceApi({ resourcePath, resourceInterface, data })

      // If the resource is a factory reset, reboot or shutdown type, remove the device from the list and redirect to devices list
      if (types?.includes?.(OIC_WK_MNT) || types?.includes?.(X_COM_KISTLER_KICONNECT_SHUTDOWN)) {
        if (resource.fr || resource.rb || resource.shutdown) {
          const deviceId = replaceDuplicateSlashes(`/${resourcePath}`).split('/', 2)[1]

          dispatch(
            batchActions([
              changeDeviceResourceInterfaceUpdateState(NORMAL),
              loadDeviceResourceInterfaceToUpdate(null),
              removeDevice(deviceId),
            ]),
          )
          dispatch(showSuccessToastFactory({ title: oa.success, message: t.youHaveBeenRedirectedToDevicesList }))

          // Redirect to devices list
          SbEmitter.emit('navigate', '/iot-hub/devices')

          return
        }
      }

      // forcing the data to clear in the component, must be in a separate dispatch
      dispatch(changeDeviceResourceInterfaceRetrieveState(LOADING))

      dispatch(
        batchActions([
          changeDeviceResourceInterfaceUpdateState(NORMAL),
          loadDeviceResourceInterfaceToUpdate(resource),
          changeDeviceResourceInterfaceRetrieveState(NORMAL),
        ]),
      )
      dispatch(showSuccessToastFactory({ title: oa.success, message: t.resourceSuccessfullyUpdated }))

      if (OAuth.isInLocalSDKMode) {
        // Event used in the guided tour
        SbEmitter.emit('resourceUpdated')
      }
    } catch ({ errors }) {
      dispatch(changeDeviceResourceInterfaceUpdateState(NORMAL))

      if (errors) {
        dispatch(setDeviceResourceInterfaceErrors(errors))
      } else {
        dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceResourceFetch }))
      }
    }
  }

export const fetchDeviceDataSources =
  (deviceId, onSuccess = () => {}, onError = () => {}) =>
  async dispatch => {
    try {
      dispatch(changeListDeviceDataSourcesState(LOADING))
      const dataSources = await getDeviceDataSourcesApi(deviceId)

      dispatch(batchActions([changeListDeviceDataSourcesState(NORMAL), setDeviceDataSources(dataSources)]))

      if (onSuccess) {
        onSuccess(dataSources)
      }
    } catch ({ errors }) {
      dispatch(changeListDeviceDataSourcesState(NORMAL))

      if (errors) {
        handleErrors({ errors }, dispatch)
      } else {
        dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourcesFetch }))
      }

      if (onError) {
        onError()
      }
    }
  }

export const fetchDeviceDataSource =
  (deviceId, dataSourceId, onSuccess = () => {}, onError = () => {}) =>
  async dispatch => {
    try {
      dispatch(changeDeviceDataSourcesState(LOADING))
      const dataSource = await getDeviceDataSourceApi(deviceId, dataSourceId)
      const dataSourceConfiguration = await getDeviceDataSourceConfigurationApi(deviceId, dataSourceId)

      dispatch(
        batchActions([
          changeDeviceDataSourcesState(NORMAL),
          loadDeviceDataSourceToUpdate(dataSource),
          loadDeviceDataSourceConfigurationToUpdate(dataSourceConfiguration),
        ]),
      )

      if (onSuccess) {
        onSuccess()
      }
    } catch ({ errors }) {
      dispatch(changeDeviceDataSourcesState(NORMAL))

      if (errors) {
        handleErrors({ errors }, dispatch)
      } else {
        dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourcesFetch }))
      }

      if (onError) {
        onError()
      }
    }
  }

export const updateDeviceDataSource = (deviceId, dataSourceId, data) => async dispatch => {
  try {
    dispatch(changeUpdateDeviceDataSourceState(LOADING))
    await setDeviceDataSourceApi(deviceId, dataSourceId, data)

    dispatch(changeUpdateDeviceDataSourceState(NORMAL))
    dispatch(showSuccessToastFactory({ title: oa.success, message: t.dataSourceSuccessfullyUpdated }))
  } catch ({ errors }) {
    dispatch(changeUpdateDeviceDataSourceState(NORMAL))

    if (errors) {
      handleErrors({ errors }, dispatch)
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourceUpdate }))
    }
  }
}

export const fetchDeviceDataSourceConfiguration = (deviceId, dataSourceId) => async dispatch => {
  try {
    dispatch(changeRetrieveDeviceDataSourceConfigurationState(LOADING))
    const dataSourceConfiguration = await getDeviceDataSourceConfigurationApi(deviceId, dataSourceId)

    dispatch(
      batchActions([
        changeRetrieveDeviceDataSourceConfigurationState(NORMAL),
        loadDeviceDataSourceConfigurationToUpdate(dataSourceConfiguration),
      ]),
    )
  } catch ({ errors }) {
    dispatch(changeRetrieveDeviceDataSourceConfigurationState(NORMAL))

    if (errors) {
      handleErrors({ errors }, dispatch)
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourceConfigurationFetch }))
    }
  }
}

export const updateDeviceDataSourceConfiguration = (deviceId, dataSourceId, data) => async dispatch => {
  try {
    dispatch(changeUpdateDeviceDataSourceConfigurationState(LOADING))
    const dataSourceConfiguration = await setDeviceDataSourceConfigurationApi(deviceId, dataSourceId, data)

    dispatch(changeRetrieveDeviceDataSourceConfigurationState(LOADING)) // forcing the data to clear in the component
    dispatch(
      batchActions([
        changeUpdateDeviceDataSourceConfigurationState(NORMAL),
        loadDeviceDataSourceConfigurationToUpdate(dataSourceConfiguration),
        changeRetrieveDeviceDataSourceConfigurationState(NORMAL),
      ]),
    )
    dispatch(showSuccessToastFactory({ title: oa.success, message: t.dataSourceConfigurationSuccessfullyUpdated }))
  } catch ({ errors }) {
    dispatch(changeUpdateDeviceDataSourceConfigurationState(NORMAL))

    if (errors) {
      // handleErrors({ errors }, dispatch)
      dispatch(setDeviceDataSourceConfigurationErrors(errors))
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourceConfigurationUpdate }))
    }
  }
}

export const fetchDeviceDataSourceTransferConfiguration =
  (deviceId, dataSourceId, transferMethod) => async dispatch => {
    try {
      dispatch(changeDeviceDataSourceTransferConfigurationState(LOADING))
      const dataSourceTransferConfiguration = await getDeviceDataSourceTransferConfigurationApi(
        deviceId,
        dataSourceId,
        transferMethod,
      )

      dispatch(
        batchActions([
          changeDeviceDataSourceTransferConfigurationState(NORMAL),
          setDeviceDataTransferConfiguration(dataSourceTransferConfiguration),
        ]),
      )

      if (OAuth.isInLocalSDKMode) {
        // Event used in the guided tour
        SbEmitter.emit('datasourceTabChanged', transferMethod)
      }
    } catch ({ errors }) {
      dispatch(changeDeviceDataSourceTransferConfigurationState(NORMAL))

      if (errors) {
        handleErrors({ errors }, dispatch)
      } else {
        dispatch(
          showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourceTranferConfigurationFetch }),
        )
      }
    }
  }

export const startDataTransfer =
  (deviceId, dataSourceId, transferMethod, { encoding, shareWithTenant, caCertificate, certificateType, ...data }) =>
  async dispatch => {
    try {
      dispatch(changeDataDataSourceTransferState(LOADING))

      let oauthConfig = null

      // Call the /connect/token/streaming API when the transfer method is STREAM (kafka)
      // and include the returned parameter to the body.
      if (
        transferMethod === supportedTransferMethods.STREAM &&
        !OAuth.isInLocalSDKMode &&
        certificateType === certificateTypes.OAUTH_KAFKA
      ) {
        const rawConfig = await requestStreamingAuthInfoApi(shareWithTenant)
        oauthConfig = JSON.parse(rawConfig)
      }

      const body = {
        ...data,
        ...(encoding ? { encoding } : {}), // Dont send the encoding param if not set, or set to automatic: ''
        ...(oauthConfig ? { oauthConfig } : {}),
        ...(transferMethod === supportedTransferMethods.STREAM && certificateType === certificateTypes.TLS_CUSTOM
          ? { caCertificate }
          : {}),
      }

      await startDeviceDataSourceApi(deviceId, dataSourceId, transferMethod, body)

      dispatch(
        batchActions([
          changeDataDataSourceTransferState(NORMAL),
          setDeviceDataTransferActiveTransferMethod(transferMethod, STARTED),
          addRunningMeasurement({ deviceId, dataSourceId, method: transferMethod }),
        ]),
      )
    } catch ({ errors }) {
      dispatch(changeDataDataSourceTransferState(NORMAL))

      if (errors) {
        handleErrors({ errors }, dispatch)
      } else {
        dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourceTranferStart }))
      }
    }
  }

export const stopDataTransfer = (deviceId, dataSourceId) => async dispatch => {
  try {
    dispatch(changeDataDataSourceTransferState(LOADING))
    await stopDeviceDataSourceApi(deviceId, dataSourceId)

    dispatch(
      batchActions([
        changeDataDataSourceTransferState(NORMAL),
        setDeviceDataTransferActiveTransferMethod(null, STOPPED),
        removeRunningMeasurement(dataSourceId),
      ]),
    )

    // If the encoding is kistler.stream.v2, ask the user if he wants to get a report from jBeam
    // Ask only if not in local mode!
    // if (OAuth.isInNormalMode && jBeamData?.encoding === KISTLER_STREAM_V2) {
    //   dispatch(
    //     openConfirmModal({
    //       title: _(t.createDataCustomSetTitle),
    //       message: _(t.createDataCustomSetMessage),
    //       confirmButtonTitle: _(oa.yes),
    //       cancelButtonTitle: _(oa.no),
    //       handleOnConfirm: () => {
    //         dispatch(closeModal())

    //         // Redirect to the jBeam page and prefill the form with the jBeamData
    //         history.push(jBeamNavigation.HOME, {
    //           jBeamData,
    //         })
    //       },
    //     }),
    //   )
    // }
  } catch ({ errors }) {
    dispatch(changeDataDataSourceTransferState(NORMAL))

    if (errors) {
      handleErrors({ errors }, dispatch)
    } else {
      dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDeviceDataSourceTranferStop }))
    }
  }
}

/* eslint-disable prefer-spread */
// Returns an unique array of all types/interfaces.
export const getColorsForResource = (resources, key) => {
  // Flattens the array and makes the values unique
  const values = uniq(
    [].concat.apply(
      [],
      resources.map(resource => resource[key]),
    ),
    true,
  ) //eslint-disable-line prefer-spread

  return getColorsObjectFromArray(values)
}
/* eslint-enable prefer-spread */

// Returns an object containing a configuration used to render a bullet for the Onboarding status
export const getOnboardingStatusBulletConfig = (cloudProvisioningStatus, _) => {
  switch (cloudProvisioningStatus) {
    case READYTOREGISTER:
      return { color: ORANGE, text: _(t.readyToRegister) }
    case REGISTERING:
      return { color: ORANGE, text: _(t.registering) }
    case REGISTERED:
      return { color: GREEN, text: _(t.registered) }
    case FAILED:
      return { color: RED, text: _(t.failed) }
    case UNINITIALIZED:
      return { color: DARK, text: _(t.uninitialized) }
    case UNKNOWN:
      return { color: DARK, text: _(t.unknown) }
    default:
      return { color: DARK, text: _(t.notAvailable) }
  }
}

// Returns an object containing a configuration used to render a bullet for the Security fields
export const getSecurityBulletConfig = (isSecured, ownershipStatus, _) => {
  if (!isSecured) {
    return { color: DARK, text: _(t.unsecured) }
  }

  switch (ownershipStatus) {
    case READY_TO_BE_OWNED:
      return { color: ORANGE, text: _(t.readyToBeOwned) }
    case OWNED:
      return { color: GREEN, text: _(t.owned) }
    case OWNED_BY_OTHER:
      return { color: RED, text: _(t.ownedByOther) }
    default:
      return { color: DARK, text: _(t.unknown) }
  }
}

// Return a cloudProvisioningStatus based on the provided status (only happens on older device 2.6.0)
export const getCloudProvisioningStatusFromStatus = status => {
  switch (parseInt(status, 10)) {
    case 1:
      return UNINITIALIZED
    case 2:
      return READYTOREGISTER
    case 3:
      return REGISTERING
    case 4:
      return REGISTERED
    case 5:
      return FAILED
    default:
      return UNKNOWN
  }
}

let deviceUsableWebsocket
const handlePlgdHubRegisterEvents = () => {
  // Correlation IDs
  const registeredCorrelationId = v4()
  const metadataCorrelationId = v4()

  const devicesUrl = `${OAuth.config.API_ENDPOINT_URL.replace(/^http/, 'ws')}/api/v1/ws/events`
  const accessTokenInProtocols = ['Bearer', OAuth.oAuthHandler.getAccessToken().accessToken]
  deviceUsableWebsocket = new ReconnectingWebSocket(devicesUrl, accessTokenInProtocols)
  deviceUsableWebsocket.addEventListener('open', () => {
    deviceUsableWebsocket.send(
      JSON.stringify({
        createSubscription: {
          eventFilter: [
            'REGISTERED',
            'UNREGISTERED',
            'RESOURCE_PUBLISHED',
            'RESOURCE_CHANGED',
            /* 'DEVICE_METADATA_UPDATED', -> this might be used in a future for online/offline handling */
          ],
          // resourceIdFilter: ['/oic/d'], this does not work for now - resourceIdFilter uses deviceId as prefix
        },
        correlationId: registeredCorrelationId,
      }),
    )

    deviceUsableWebsocket.send(
      JSON.stringify({
        createSubscription: {
          eventFilter: ['DEVICE_METADATA_UPDATED'],
        },
        correlationId: metadataCorrelationId,
      }),
    )
  })

  let registerTicketId
  const registered = {}
  const numResourcePublished = {}
  const numResourceChanged = {}
  const emitRegisteredWhenReady = deviceId => {
    if (
      registered[deviceId] &&
      numResourcePublished[deviceId] &&
      numResourceChanged[deviceId] === numResourcePublished[deviceId]
    ) {
      clearTimeout(registerTicketId)
      SbEmitter.emit(DEVICE_REGISTER_LISTENER_NAME, { deviceId, status: deviceStatuses.REGISTERED })
    }
  }

  deviceUsableWebsocket.addEventListener('message', event => {
    const json = JSON.parse(event.data)
    if (json.error) {
      if (json.error?.message?.includes('token is expired')) {
        SbEmitter.emit('oAuth.TriggerSilentRenew')
        return
      }
      batchLogMessages(json)
      return
    }

    const message = json?.result || {}
    const correlationId = message.correlationId

    if (correlationId === registeredCorrelationId) {
      if (message.operationProcessed?.errorStatus?.code === 'OK') {
        return
      }
      if (!(message.resourceChanged?.status !== 'ok')) {
        batchLogMessages(message)
      }
      message.deviceRegistered?.deviceIds?.forEach?.(id => {
        registered[id] = true
        emitRegisteredWhenReady(id)
      })
      if (message.resourcePublished?.deviceId) {
        const id = message?.resourcePublished?.deviceId
        numResourcePublished[id] = message.resourcePublished?.resources?.length || 0
        emitRegisteredWhenReady(id)
      }
      if (message.resourceChanged?.status === 'OK' && message.resourceChanged.resourceId?.deviceId) {
        const id = message.resourceChanged.resourceId.deviceId
        numResourceChanged[id] = numResourceChanged[id] + 1 || 1 // increment or initialize to 1 (if not defined before)
        clearTimeout(registerTicketId)
        registerTicketId = setTimeout(() => {
          if (!areSkydaqAPISDisabled()) {
            axios
              .post(`${getStudioAPIHost()}/api/log`, {
                result: 'Device resource registration timed out',
                state: {
                  registered: registered[id],
                  numResourcePublished: numResourcePublished[id] ?? null,
                  numResourceChanged: numResourceChanged[id],
                },
              })
              .catch(() => {})
          }
          SbEmitter.emit(DEVICE_REGISTER_LISTENER_NAME, { deviceId: id, status: deviceStatuses.REGISTERED })
        }, 30_000)
        emitRegisteredWhenReady(id)
      }
      message.deviceUnregistered?.deviceIds?.forEach?.(id => {
        registered[id] = false
        numResourcePublished[id] = 0
        numResourceChanged[id] = 0
      })
    } else if (correlationId === metadataCorrelationId) {
      const metadata = message?.deviceMetadataUpdated
      const status = String(metadata?.connection?.status).toLowerCase()
      if (status === deviceStatuses.ONLINE && metadata?.twinSynchronization?.state === 'IN_SYNC') {
        devicesStatusListener({
          data: JSON.stringify({ deviceId: metadata?.deviceId, status, inSync: true }),
        })
      }
    }
  })
}

export const subscribeToDevicesStatusWS = () => {
  if (!WSClients.ws[deviceStatusWebsocketKey]) {
    WSClients.addToWsClients({
      name: deviceStatusWebsocketKey,
      endpoint: '/ws/devices',
      listener: devicesStatusListener,
      permission: iotHubPermissions.kiconnectDevicesRead,
      delayListener: 0,
    })

    SbEmitter.emit('startObservingDevices')

    if (OAuth.isInNormalMode) {
      handlePlgdHubRegisterEvents()
    }
    // else {
    //   const timeoutDevices = {}
    //   SbEmitter.on(DEVICE_STATUS_LISTENER_NAME, ({ deviceId, status }) => {
    //     if (status === ONLINE) {
    //       timeoutDevices[deviceId] = setTimeout(() => {
    //         SbEmitter.emit(DEVICE_STATUS_LISTENER_NAME, { deviceId, status: deviceStatuses.REGISTERED })
    //       }, 5000)
    //     } else if (status === OFFLINE) {
    //       clearTimeout(timeoutDevices[deviceId])
    //     }
    //   })
    // }
  }
}

// Returns a list of devices which has the same PIID or serial number
// device -> the device we want to know if it has a duplicate
// list -> the list of all devices
export const getDuplicatePIIDorSerialNumberRows = (device, list) =>
  list.filter(
    r =>
      !!r.protocolIndependentId &&
      !!device.protocolIndependentId &&
      (r.protocolIndependentId === device.protocolIndependentId || r.serialNumber === device.serialNumber) &&
      r.id !== device.id,
  )

export const filterDevice = (deviceList, searchValue) =>
  deviceList.filter(
    d => searchIn(d.id, searchValue) || searchIn(d.name, searchValue) || searchIn(d.status, searchValue),
  )
