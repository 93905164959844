// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import update from 'immutability-helper'
import { map, intersection, isEmpty, throttle } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { messages as a } from 'skybase-oauth/messages-i18n'
import { showErrorToast } from 'skybase-ui/skybase-components/sb-toastr/actions'
import { setAlerts } from '@/fleet-configuration/validation/actions'
import { objectDiffDeep } from '@/utils/diff'
import { guardedDeviceFactory } from '@/fleet-configuration/data-fleet/project-devices/device-factory'
import { deviceSyncStatusUpdate } from '@/fleet-configuration/data-fleet/device-sync/device-sync-actions'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import { addOnlineStatusToDeviceModel } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-utils'
import {
  processFinished,
  processStartedIfFulfilled,
} from '@/fleet-configuration/data-fleet/process-indicator/process-indicator-actions'
import {
  removeComponentActiveProject,
  addComponentToActiveProject,
  loadActiveProjectComponentsIfEmpty,
} from '@/fleet-configuration/data-fleet/components/components-actions'
import { componentFamily } from '@/fleet-configuration/data-fleet/components/components-constants'
import {
  getProjectComponents,
  getProjectComponentsByFamily,
} from '@/fleet-configuration/data-fleet/components/components-selectors'
import { isValid } from '@/fleet-configuration/validation/is-valid'
import { fixateAllTypesInDevices } from '@/fleet-configuration/utils/fixate-all-types-in-devices'
import { getDeviceSyncStartedProcessName } from '@/fleet-configuration/data-fleet/device-sync/device-sync-selectors'
import { loadDevicesIfEmpty } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { setProjectDeviceDirtyProps } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-actions'
import { deviceSyncOperationNames } from '@/fleet-configuration/data-fleet/device-sync/device-sync-constants'
import { getDeviceOnlineStatus } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-selector'
import { getStudioAPIHost } from '@/utils/url'
import { getDeviceById, getDevices } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { CONFIGURABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { batchSetCatalogComponents, loadDevicesCatalog } from '@/fleet-configuration/data-fleet/catalog/catalog-actions'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { getProjectDeviceById } from './project-devices-selectors'
import { messages as t } from './project-devices-actions-i18n'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { loadDeviceLastState } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-actions'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { fetchComponentByType } from '@/fleet-configuration/equipment'
import { ACQUISITION_DEVICE_TYPE } from '@/common/device-type-constants'

export const SET_PROJECT_DEVICE = getActionName('SET_PROJECT_DEVICE')
export const initialSetProjectDevice = device => createAction(SET_PROJECT_DEVICE, device)

export const REMOVE_PROJECT_DEVICE = getActionName('REMOVE_PROJECT_DEVICE')
export const removeProjectDevice = deviceId => createAction(REMOVE_PROJECT_DEVICE, { deviceId })

export const RELOADING_PROJECT_DEVICE = getActionName('RELOADING_PROJECT_DEVICE')
export const reloadingProjectDevice = deviceId => createAction(RELOADING_PROJECT_DEVICE, { deviceId })

const { DEVICE } = componentFamily

export const tryAddDeviceToProject = async (dispatch, getState, deviceId) => {
  const state = getState()
  const device = getDeviceById(state, deviceId)
  if (!device?.online) {
    return false
  }
  const normalizedDeviceId = device.protocolIndependentId || device.id
  const projectDevice = getProjectDeviceById(state, normalizedDeviceId)
  if (projectDevice) {
    return false
  }
  const { id, manufacturerName, ...deviceComponentProps } = device
  const deviceComponent = {
    ...deviceComponentProps,
    deviceId: device.id,
    protocolIndependentId: device.protocolIndependentId,
    catalogId: device.modelNumber,
    family: DEVICE,
  }
  return dispatch(addComponentToActiveProject(deviceComponent))
}

export const removeOldProjectDevices = async (getState, dispatch) => {
  const state = getState()
  const existingProjectComponents = getProjectComponentsByFamily(state, 'device')
  const devices = getDevices(state).filter(device => device.types?.includes(ACQUISITION_DEVICE_TYPE))
  const deviceIds = devices.reduce((acc, { protocolIndependentId, id }) => {
    acc.push(protocolIndependentId, id)
    return acc
  }, [])
  const asyncActionPromises = []

  // TODO: If this ever becomes performance bottleneck - implement Divide&Conquer algorithm ( O(N*log(N) )
  //  That would be sort both collections by ID and compare only "top" items
  //     - when one is missing - you can directly add/remove it - depending on which collection it is
  //  But as we don't have that many devices right now, I will use "stupid" (easy to reason, slow) version ( O(N^2) )
  existingProjectComponents.forEach(projectDevice => {
    if (!deviceIds.includes(projectDevice.deviceId)) {
      // eat-up error so at least other devices get loaded...
      // Note that error is still handled via axios interceptor and toast is shown
      asyncActionPromises.push(dispatch(removeComponentActiveProject(projectDevice)).catch(() => null)) // remove needs just any object with specified id property
    }
  })
  return Promise.all(asyncActionPromises)
}

let areProjectDeviceDependenciesLoaded = false
let projectDeviceDependenciesPromise
const projectDeviceBeingLoadedPromises = {}
export const loadProjectDevice =
  (deviceId, suppressErrors = false) =>
  async (dispatch, getState) => {
    let projectId = getActiveProjectId(getState())
    if (!projectId) {
      await dispatch(loadProjectsIfEmpty())
      projectId = getActiveProjectId(getState())
      if (!projectId) {
        console.warn("Can't load project devices - project ID empty or projects not initialized yet")
        return undefined
      }
    }
    if (!areProjectDeviceDependenciesLoaded) {
      if (!projectDeviceDependenciesPromise) {
        projectDeviceDependenciesPromise = Promise.all([
          dispatch(loadDevicesIfEmpty()),
          dispatch(loadActiveProjectComponentsIfEmpty()),
        ])
      }
      await projectDeviceDependenciesPromise
      areProjectDeviceDependenciesLoaded = true
    }

    const device = getDeviceById(getState(), deviceId)
    if (!device) {
      console.warn('Trying to load project device when corresponding device does not exist', deviceId)
      return false
    }
    // do not try to load devices, that are not configurable (hence they can't be in project)
    if (!intersection(device.types, CONFIGURABLE_DEVICE_TYPES).length) {
      return false
    }

    let deviceData // deduplicate request calls for project device (collapse all request until first call-chain is finished)
    if (projectDeviceBeingLoadedPromises[deviceId]) {
      deviceData = await projectDeviceBeingLoadedPromises[deviceId]
    } else {
      projectDeviceBeingLoadedPromises[deviceId] = (async () => {
        let isInProject = getProjectComponents(getState()).find(
          component => component.deviceId === deviceId && component.family === DEVICE,
        )
        if (!isInProject && device.online) {
          isInProject = await tryAddDeviceToProject(dispatch, getState, deviceId)
          if (!isInProject) {
            console.error('Could not load project device "%s" - also adding device to project failed', deviceId)
            return false
          }
        } else if (!isInProject) {
          return false
        }

        return axios
          .get(
            `${getStudioAPIHost()}/api/projects/${projectId}/devices/${deviceId}`,
            suppressErrors
              ? {
                  customErrorHandler: error => {
                    console.error(error)
                  },
                }
              : {},
          )
          .then(async ({ data }) => {
            fixateAllTypesInDevices([data])
            await dispatch(loadDevicesCatalog([data]))
            return data
          })
      })()
      deviceData = await projectDeviceBeingLoadedPromises[deviceId]
      delete projectDeviceBeingLoadedPromises[deviceId] // this delete marks -> next load will actually do request
    }
    if (!deviceData) {
      return false
    }

    const projectDevice = addOnlineStatusToDeviceModel(getDeviceOnlineStatus(getState(), deviceId), deviceData)
    const deviceModel = guardedDeviceFactory(dispatch, getState, projectDevice)
    dispatch(initialSetProjectDevice(deviceModel))
    return deviceModel
  }

const loadCatalogErrorHandler = throttle((e, dispatch) => {
  dispatch(showErrorToast(t.failedToFetchCatalog, { title: e?.message ?? t.componentNotFound }, a.error))
}, 10000)

export const loadCatalog = deviceType => async dispatch => {
  try {
    const component = await fetchComponentByType(deviceType)
    if (!isEmpty(component)) {
      await dispatch(batchSetCatalogComponents(component))
      return component
    }
  } catch (e) {
    if (e?.status && e.status === 404) {
      loadCatalogErrorHandler(e, dispatch)
    } else {
      throw e
    }
  }
  return false
}

const projectDeviceBeingLoaded = {}
export const loadProjectDeviceIfEmpty =
  deviceId =>
  async (dispatch, getState, ...args) => {
    const data = getProjectDeviceById(getState(), deviceId) || projectDeviceBeingLoaded[deviceId]
    if (data) {
      return data
    }
    const loadResult = loadProjectDevice(deviceId)(dispatch, getState, ...args)
    // remove duplicate load request when this method is called during ongoing request
    if (loadResult.then) {
      projectDeviceBeingLoaded[deviceId] = loadResult
      const removeProjectDeviceLoadingCache = () => {
        delete projectDeviceBeingLoaded[deviceId]
      }
      loadResult.then(removeProjectDeviceLoadingCache, removeProjectDeviceLoadingCache)
    }

    return loadResult
  }

export const setProjectDevice = device => dispatch => {
  device.clearValidationResult() // storing new device means validation result needs to be cleared
  dispatch(initialSetProjectDevice(device))
  dispatch(setProjectDeviceDirtyProps(device.id))
  return device
}

let controller
let lastDeviceId
let ticket = 0
const lastCommittedModifiedTime = { ticket: 0, time: null }
export const saveProjectDevice =
  (device, abortAble = true) =>
  async (dispatch, getState) => {
    const state = getState()
    const projectId = getActiveProjectId(state)
    // refresh state with current model as to update dirty flags
    const deviceData = device.toDto?.() || device
    const deviceModel = device.toDto ? device : guardedDeviceFactory(dispatch, getState, deviceData)
    dispatch(setProjectDevice(deviceModel))
    if (controller && lastDeviceId === device.id) {
      controller.abort()
    }
    controller = new AbortController()
    lastDeviceId = device.id
    ticket += 1
    const currentTicket = ticket
    const { data, status: responseStatus } = await axios.put(
      `${getStudioAPIHost()}/api/projects/${projectId}/devices/${device.id}`,
      deviceData,
      {
        validateStatus: status => (status >= 200 && status < 300) || status === 304, // add 304 as a correct status response
        signal: abortAble ? controller.signal : undefined,
      },
    )
    controller = null
    // don't change anything on responses like 304. We won't get any response in those cases
    if (responseStatus === 200 && currentTicket === ticket) {
      // refresh device model so that lastModifiedTimestamp is taken into account
      const currentDevice = getProjectDeviceById(getState(), deviceModel.id)
      if (currentDevice?.toDto) {
        const newDeviceModel = guardedDeviceFactory(dispatch, getState, {
          ...currentDevice.toDto(),
          lastModifiedTimestamp: data.lastModifiedTimestamp,
        })
        dispatch(initialSetProjectDevice(newDeviceModel))
      }
    } else if (responseStatus === 200) {
      // suppose we have a race condition, where older request succeeds (sets lastModifiedTime), but newer request fails (e.g. 500)
      // in this case "lastModifiedTimestamp" will not be updated, because older request will be ignored (old ticket) and newer request
      // will have responseStatus !== 200.
      // To circumvent this, we will mark last successful request that's ignored by race condition's ticket (and we always take only the newest one if more request fails)
      // and at the very last request (where currentTicket === ticket) if said request fails - then we will use that stored race-conditioned value.
      if (currentTicket > lastCommittedModifiedTime.ticket) {
        lastCommittedModifiedTime.ticket = currentTicket
        lastCommittedModifiedTime.time = data.lastModifiedTimestamp
      }
    } else if (currentTicket === ticket && lastCommittedModifiedTime.ticket > 0) {
      const currentDevice = getProjectDeviceById(getState(), deviceModel.id)
      if (currentDevice?.toDto) {
        const newDeviceModel = guardedDeviceFactory(dispatch, getState, {
          ...currentDevice.toDto(),
          lastModifiedTimestamp: lastCommittedModifiedTime.time,
        })
        dispatch(initialSetProjectDevice(newDeviceModel))
      }
    }

    return deviceModel
  }

const storeMultipleProjectDevicesHelper = (devices, dispatch) => {
  devices.forEach(device => {
    dispatch(setProjectDevice(device))
  })
}

export const setProjectDeviceModule = (deviceId, moduleIndex, module) => (dispatch, getState) => {
  const state = getState()
  const device = getProjectDeviceById(state, deviceId)
  const deviceUpdateSpec =
    moduleIndex === urlPathController
      ? { controller: { $set: module } }
      : { modules: { [moduleIndex]: { $set: module } } }
  const updatedDevice = update(device, deviceUpdateSpec)
  dispatch(setProjectDevice(updatedDevice))
  return updatedDevice
}

const updateDeviceModuleDataSource = (device, moduleIndex, dataSourceId) => {
  const module = device.getModuleById(moduleIndex)
  const channels = module.getChannels().map(channel => ({
    ...channel,
    parameters: { ...channel.parameters, dataSourceId },
  }))
  const updatedModule = update(module, {
    samplingRate: { $set: dataSourceId },
    channels: { $set: channels },
  })
  updatedModule.initChannels()
  const deviceUpdateSpec =
    moduleIndex === urlPathController
      ? { controller: { $set: updatedModule } }
      : { modules: { [moduleIndex]: { $set: updatedModule } } }
  return update(device, deviceUpdateSpec)
}

export const setProjectDeviceModuleDataSource = (deviceId, moduleIndex, dataSource) => (dispatch, getState) => {
  const state = getState()
  const device = getProjectDeviceById(state, deviceId)
  const updatedDevice = updateDeviceModuleDataSource(device, moduleIndex, dataSource)
  return dispatch(setProjectDevice(updatedDevice))
}

export const setProjectDeviceModuleDataSourceForChannel = channel => (dispatch, getState) => {
  const moduleId = channel.getModuleIndex()
  const device = getProjectDeviceById(getState(), channel.getDeviceId())
  const module = device.getModuleById(moduleId)
  const dataSource = module.samplingRate || device.getSamplingRateOptions()[0].value
  return dispatch(setProjectDeviceModuleDataSource(device.id, moduleId, dataSource))
}

const updateDeviceChannel = (device, channel) => {
  const moduleIndex = channel.getModuleIndex()
  const channelIndex = channel.getChannelIndex()
  const channelUpdateSpec = { channels: { [channelIndex]: { $set: channel } } }
  const deviceUpdateSpec =
    moduleIndex === urlPathController
      ? { controller: channelUpdateSpec }
      : { modules: { [moduleIndex]: channelUpdateSpec } }
  return update(device, deviceUpdateSpec)
}

export const setProjectDeviceChannels = channels => (dispatch, getState) => {
  const state = getState()
  const updateDevices = channels.reduce((acc, channel) => {
    const deviceId = channel.getDeviceId()
    const device = acc[deviceId] || getProjectDeviceById(state, deviceId)
    const updatedDevice = updateDeviceChannel(device, channel)
    acc[updatedDevice.id] = updatedDevice
    return acc
  }, {})

  const recreatedDevices = map(updateDevices, device => guardedDeviceFactory(dispatch, getState, device))
  storeMultipleProjectDevicesHelper(recreatedDevices, dispatch)
  return recreatedDevices
}

export const setProjectDeviceChannel = channel => (dispatch, getState) => {
  const device = getProjectDeviceById(getState(), channel.getDeviceId())
  const updatedDevice = updateDeviceChannel(device, channel)
  dispatch(setProjectDevice(updatedDevice))
  return updatedDevice
}

export const trySaveValidProjectDevice = deviceIdOrObject => async (dispatch, getState) => {
  const state = getState()
  const device = typeof deviceIdOrObject === 'string' ? getProjectDeviceById(state, deviceIdOrObject) : deviceIdOrObject
  if (device && isValid(state, device.id)) {
    const result = await dispatch(saveProjectDevice(device))
    dispatchEvent(new CustomEvent('try-save-device', { detail: { deviceId: device.id } }))
    return result
  }
  return false
}

export const updateProjectDeviceById = (deviceId, updateSpec) => (dispatch, getState) => {
  const state = getState()
  const device = getProjectDeviceById(state, deviceId)
  if (!device) {
    return null
  }
  const updatedDevice = update(device, updateSpec)
  if (!Object.keys(objectDiffDeep(updatedDevice, device)).length) {
    return null
  }
  const deviceModel = guardedDeviceFactory(dispatch, getState, updatedDevice)
  dispatch(setProjectDevice(deviceModel))
  return deviceModel
}

export const syncProjectDevice =
  (device, intlFormatMessage, forceWrite = false) =>
  (dispatch, getState) => {
    const projectId = getActiveProjectId(getState())
    const result = axios
      .post(`${getStudioAPIHost()}/api/devices/${device.id}/sync${forceWrite ? '?force=true' : ''}`, device.toDto(), {
        customErrorHandler: error => {
          const operationStatusCode = (!!error.response && error.response.status) || 503
          const eventDate = new Date()
          dispatch(
            deviceSyncStatusUpdate({
              deviceId: device.id,
              eventName: 'deviceSync',
              eventTime: eventDate.getTime(),
              operationName: deviceSyncOperationNames.ENDED,
              operationStatusCode,
              projectId,
              timestamp: eventDate.toJSON(),
            }),
          )
          const deviceIdentifier = device?.name || device.id
          showErrorToast(
            { message: t.writeToDeviceDeviceWasUnsuccessful, params: { device: deviceIdentifier } },
            t.writeToDeviceFailed,
          )
          dispatch(processFinished(getDeviceSyncStartedProcessName(device.id)))
          if (![409, 503].includes(operationStatusCode)) {
            console.error(error)
          }
        },
        headers: {
          'initiator-project': projectId,
        },
      })
      .catch(error => {
        console.error(error)
        const eventDate = new Date()
        dispatch(
          batchActions([
            processFinished(getDeviceSyncStartedProcessName(device.id)),
            deviceSyncStatusUpdate({
              deviceId: device.id,
              eventName: 'deviceSync',
              eventTime: eventDate.getTime(),
              operationName: deviceSyncOperationNames.ENDED,
              operationStatusCode: 500,
              projectId,
              timestamp: eventDate.toJSON(),
            }),
          ]),
        )
        return error
      })

    dispatch(processStartedIfFulfilled(getDeviceSyncStartedProcessName(device.id), result))
    dispatch(
      deviceSyncStatusUpdate({
        deviceId: device.id,
        eventName: 'deviceSync',
        operationName: deviceSyncOperationNames.STARTED,
        timestamp: new Date().toISOString(),
        projectId,
      }),
    )
    return result
  }

export const syncProjectDeviceByDeviceId = (deviceId, intlFormatMessage, forceWrite) => (dispatch, getState) =>
  dispatch(syncProjectDevice(getProjectDeviceById(getState(), deviceId), intlFormatMessage, forceWrite))

export const updateDeviceChannelParameter =
  (deviceId, moduleIndex, channelIndex, field, value) => (dispatch, getState) => {
    const device = getProjectDeviceById(getState(), deviceId)
    const module = moduleIndex === urlPathController ? device.deviceSpecific.controller : device.modules[moduleIndex]
    const sourceChannel = module.channels[channelIndex]
    const updatedChannel = sourceChannel.setParameter(field, value)
    return setProjectDeviceChannel(updatedChannel)(dispatch, getState)
  }

export const setDeviceAlerts = (deviceId, validationResults) => dispatch => {
  validationResults.forEach(({ path, alerts }) => {
    const deviceDetailUrl = `/fleet/configuration/devices/${deviceId}/`
    dispatch(
      setAlerts(
        alerts.map(alert => ({ ...alert, backUrl: deviceDetailUrl, componentId: path })),
        path,
      ),
    )
  })
}

export const setChannelAlerts = validationResults => dispatch => {
  const dispatchAbleActions = validationResults.reduce((acc, { path, alerts, deviceId, moduleIndex, channelIndex }) => {
    const sensorDetailUrl =
      moduleIndex === undefined
        ? `/fleet/configuration/devices/${deviceId}`
        : `/fleet/configuration/devices/${deviceId}/module/${moduleIndex}/channel/${channelIndex}`
    acc.push(
      ...(dispatch(
        setAlerts(
          alerts.map(alert => ({ ...alert, backUrl: sensorDetailUrl, componentId: path })),
          path,
          null,
          false,
        ),
      ) || []),
    )
    return acc
  }, [])
  if (dispatchAbleActions.length) {
    dispatch(batchActions(dispatchAbleActions))
  }
}

export const reloadDeviceConfiguration =
  (deviceId, onErrorMessage) =>
  async (dispatch, getState, ...args) => {
    dispatch(batchActions([reloadingProjectDevice(deviceId), closeModal()]))
    try {
      const deviceComponent = getProjectComponents(getState()).find(c => c.deviceId === deviceId && c.family === DEVICE)
      await dispatch(removeComponentActiveProject(deviceComponent, true))
      dispatch(removeProjectDevice(deviceId))
      // load adds device back to project
      await loadProjectDevice(deviceId, true)(dispatch, getState, ...args)
      // refresh lastKnownState collection after device was added, but before re-computation of dirty state
      // this is required for when user changes hardware configuration and then hits reload button
      await dispatch(loadDeviceLastState(deviceId))
      // now recalculate dirty state
      await dispatch(setProjectDeviceDirtyProps(deviceId))
      // and revalidate it
      const projectDevice = getProjectDeviceById(getState(), deviceId)
      if (projectDevice) {
        const validationResults = projectDevice.validate()
        dispatch(setChannelAlerts(validationResults))
      }
    } catch (e) {
      if (onErrorMessage) {
        showErrorToast(onErrorMessage)
      }
      return false
    } finally {
      dispatch(reloadingProjectDevice(null))
    }
    return true
  }
