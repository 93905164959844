import axios from 'axios'
import { areSkydaqAPISDisabled } from '@/utils'
import { getStudioAPIHost } from '@/utils/url'
import { throttle } from 'lodash/function'

let batchLoggedMessages = []
const doBatchLoggedMessages = throttle(
  () => {
    if (!areSkydaqAPISDisabled()) {
      axios.post(`${getStudioAPIHost()}/api/log`, batchLoggedMessages).catch(() => {})
    }
    batchLoggedMessages = []
  },
  5000,
  { leading: false, trailing: true },
)

export const batchLogMessages = messageToLog => {
  batchLoggedMessages.push(messageToLog)
  doBatchLoggedMessages()
}

export const initConsoleLogging = () => {
  if (!areSkydaqAPISDisabled()) {
    const originalConsoleErrorLog = console.error
    console.error = (...args) => {
      originalConsoleErrorLog(...args)

      // when writing into console, log it also to server (if it's relevant)
      if (args[0]?.startsWith) {
        // filter out errors that we don't want to see (either clutter, or normal workflow)
        if (/^(\[React Intl\]|Warning:|Incompatible sensitivity unit with physical unit|In Channel ")/.test(args[0])) {
          return
        }
      }
      batchLogMessages(
        args
          .reduce((acc, itm) => {
            if (['string', 'number', 'boolean', 'undefined'].includes(typeof itm) || itm === null) {
              acc.push(itm)
            }
            if (itm instanceof Error) {
              acc.push(itm.message + '\n\n' + itm.stack)
            }
            return acc
          }, [])
          .join('; '),
      )
    }
  }
}
