export const DEVICE_DATA_SOURCES_KEY_NAME = 'deviceDataSources'

export const DEVICES_DATA_SOURCE_STATE_KEYS = {
  DATA_SOURCES_LIST: 'list',
  DATA_SOURCE_DETAILS: 'details',
  DATA_SOURCE_CONFIGURATION: 'configuration',
  DATA_TRANSFER: 'dataTransfer',
  RUNNING_MEASUREMENTS: 'runningMeasurements',
}

export const dataSourceStates = {
  STOPPED: 'stopped',
  STARTED: 'started',
  INTERNAL_ERROR: 'internal_error',
  DEVICE_ERROR: 'device_error',
}

export const supportedTransferMethods = {
  STREAM: 'stream',
  ZMQPUBCLIENT: 'zmqpubclient',
  BATCH: 'batch',
}

const { STREAM, ZMQPUBCLIENT, BATCH } = supportedTransferMethods

export const supportedTransferMethodNames = {
  [STREAM]: 'Kafka',
  [ZMQPUBCLIENT]: 'ZMQ',
  [BATCH]: 'Batch',
}

export const DATA_SOURCE_WS_KEY = 'dataSource'

export const DATA_SOURCES_DETAILS_TAB_KEY = 'details'

export const transferFormObject = {
  ENDPOINTS: 'endpoints',
  TOPIC: 'topic',
  TIME_TRESHOLD: 'timeTreshold',
  MESSAGE_TRESHOLD: 'messageTreshold',
  ENCODING: 'encoding',
  BROKER_VERIFY_TIMEOUT_MS: 'brokerVerifyTimeoutMs',
}

export const kafkaTransferFormObject = {
  BROKER_LIST: 'brokerList',
  TOPIC_NAME: 'topicName',
  TRESHOLD_MESSAGE_COUNT: 'thresholdMessageCount',
  TRESHOLD_LINGER_MS: 'thresholdLingerMs',
  ENCODING: 'encoding',
  BROKER_VERIFY_TIMEOUT_MS: 'brokerVerifyTimeoutMs',
}

export const zeroMQTransferFormObject = {
  ENDPOINT: 'endpoint',
  TOPIC_NAME: 'topicName',
  TRESHOLD_FRAME_COUNT: 'thresholdFrameCount',
  TRESHOLD_TIME_MS: 'thresholdTimeMs',
  ENCODING: 'encoding',
}

export const encodings = {
  KISTLER_STREAM_V2: 'kistler.stream.v2',
}

export const DS_DEFAULT_PAGE_SIZE = 50

export const DS_DEFAULT_SORT_BY = {
  sortCol: 'name',
  sortOrder: 'asc',
}

export const DEFAULT_BROKER_VERIFY_TIMEOUT_MS = 1000

export const DATASOURCE_GRAPH_MODAL_KEY = 'datasource-zmq-graph'

export const DATASOURCE_GRAPH_WS_KEY = 'datasource-zmq-graph-ws'

export const ACQUISITION_CHANNEL = 'x.com.kistler.acquisition.channel'

export const certificateTypes = {
  OAUTH_KAFKA: 'oauth',
  TLS_CUSTOM: 'tls',
}

export const CA_CERTIFICATE = 'caCertificate'
