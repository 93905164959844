export const DEVICES_STATE_KEY_NAME = 'devices'

export const DEVICES_STATE_KEYS = {
  UPDATE_DEVICE: 'deviceDetails',
  LIST: 'list',
  RESOURCE_DETAILS: 'resourceDetails',
  DEVICE_ONLINE_STATUS: 'deviceOnlineStatus',
  DEVICE_REGISTER_STATUS: 'deviceRegisterStatus',
}

export const deviceStatuses = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  REGISTERED: 'registered',
  UNREGISTERED: 'unregistered',
}

export const cloudProvisioningStatuses = {
  UNINITIALIZED: 'uninitialized',
  READYTOREGISTER: 'ready_to_register',
  REGISTERING: 'registering',
  REGISTERED: 'registered',
  FAILED: 'failed',
  UNKNOWN: 'unknown',
}

export const ownershipStatuses = {
  UNKNOWN: 'unknown',
  READY_TO_BE_OWNED: 'ready_to_be_owned',
  OWNED: 'owned',
  OWNED_BY_OTHER: 'owned_by_other',
}

export const resourceStructures = {
  FLAT: 'flat',
  ARRAY: 'array',
  NONE: 'none',
}

export const OIC_WK_MNT = 'oic.wk.mnt'

export const X_COM_KISTLER_KICONNECT_SHUTDOWN = 'x.com.kistler.kiconnect.shutdown'

export const RESOURCE_MODAL_ID = 'resourceModal'

export const DEFAULT_PAGE_SIZE = 50

export const DEFAULT_SORT_BY = {
  sortCol: 'name',
  sortOrder: 'asc',
}

export const DEVICE_STATUS_LISTENER_NAME = 'device-status'

export const DEVICE_REGISTER_LISTENER_NAME = 'device-registration'

export const ADD_DEVICE_BY_IP_MODAL_ID = 'addDeviceByIpModal'

export const deviceStatusWebsocketKey = 'devicesStatus'
