import { v4 } from 'uuid'

import { debounce } from 'lodash'
import { OAuth } from 'skybase-oauth'
import { showInfoToast } from 'skybase-ui/skybase-components/sb-toastr/actions'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'

import { WSClients } from '@/common/websocket'
import { notificationTypes, NOTIFICATIONS_PAGE_URL } from '@/iot-hub/components/notifications/constants'
import { logNotification } from '@/iot-hub/components/notifications/utils'
import { getDeviceApi } from '@/iot-hub/rest'
import {
  getIotHubDevicesState,
  getIotHubDevicesListState,
  getIotHubDeviceOnlineState,
  getIotHubDeviceRegisterState,
  getOwningDeviceIds,
} from '@/iot-hub/selectors'
import { store } from '@/stores'
import { isBrowserTabActive } from '@/utils/is-browser-tab-active'

import { batchActions } from 'redux-batched-actions'
import { updateDeviceOnlineStatus, updateDeviceRegisterStatus, removeDevice, addDevice } from './actions'
import { DEVICE_REGISTER_LISTENER_NAME, DEVICE_STATUS_LISTENER_NAME, deviceStatuses } from './constants'
import { messages as t } from './devices-i18n'

const { ONLINE, OFFLINE, REGISTERED, UNREGISTERED } = deviceStatuses

const handleOnToastClick = (onRemove, dispatch, notificationId) => {
  // Remove/Hide the sw licensing toast
  onRemove()

  // Redirect to the notifications page
  SbEmitter.emit('navigate', NOTIFICATIONS_PAGE_URL, {
    state: { selectedId: notificationId },
  })
}

let logNotificationActions
const handleDeviceStateNotification = (message, deviceInList) => {
  const { deviceId, status } = message
  const name = deviceInList?.name || 'Unknown'
  const title = t.statusOfTheDeviceTitle
  const text = {
    message:
      status === REGISTERED || status === UNREGISTERED
        ? t.registerStatusOfTheDeviceHasChangedTo
        : t.statusOfTheDeviceHasChangedTo,
    params: { deviceName: name, status },
  }
  const notificationId = v4()

  // Show toast only when browser tab is active
  if (isBrowserTabActive()) {
    // dispatch toasts when status change
    store.dispatch(
      showInfoToast(
        {
          title,
          message: text,
        },
        {
          className: 'sb-cursor-pointer',
          hideTime: 5000,
          closeButton: true,
          onClick: (event, onRemove) => handleOnToastClick(onRemove, store.dispatch, notificationId),
        },
      ),
    )
  }

  // Log notification
  store.dispatch(
    logNotification({
      id: notificationId,
      type: notificationTypes.INFO,
      title,
      message: text,
      payload: message,
      redirectLocation: `/iot-hub/devices${status === UNREGISTERED ? '' : `/${deviceId}`}`,
    }),
  )
}
const handleDeviceStatusError = () => {
  // Ignoring this error for now, in the future we will implement some logger for these errors instead of showing them in toast.
  // store.dispatch(showErrorToastFactory({ title: oa.error, message: t.errorDuringDevicesFetch }))
}
const byIdOnlineStateMessages = {}
const byIdRegistrationStateMessages = {}
const batchProcessDeviceStatus = debounce(
  // TODO: NOTE: Monitor performance of this part - if it is initially too slow for large number of devices
  //  then reduce number of initial requests by defining initial state of websocket online/offline status from REST
  //  request for devices (this REST is not authoritative, but it works if no information from websocket arrived yet)
  async () => {
    const state = store.getState()
    const { enableNotifications } = getIotHubDevicesState(state)
    const { data: deviceList } = getIotHubDevicesListState(state)

    const existingOnlineState = getIotHubDeviceOnlineState(state)
    const uniqByIdOnlineStateMessages = Object.values(byIdOnlineStateMessages).filter(
      ({ deviceId, status, inSync }) => existingOnlineState[deviceId] !== status || inSync,
    )

    const updateOnlineStateActions = []
    const allDevicesInList = []
    uniqByIdOnlineStateMessages.forEach(({ deviceId, status, inSync }) => {
      try {
        if (existingOnlineState[deviceId] !== status) {
          updateOnlineStateActions.push(updateDeviceOnlineStatus(deviceId, status))
          SbEmitter.emit(DEVICE_STATUS_LISTENER_NAME, { deviceId, status })
        }
        const deviceInList = deviceList.find(d => d.id === deviceId)
        const deviceIsOwning = getOwningDeviceIds(state).includes(deviceId)
        // In local mode, we don't have the inSync info, so we hardcode it to true
        const deviceInSync = !OAuth.isInNormalMode ? true : inSync
        if (!deviceInList && !deviceIsOwning && status === ONLINE && deviceInSync) {
          // Fetch the new device
          allDevicesInList.push(
            getDeviceApi(deviceId).catch(err => {
              console.error('Could not fetch device', err)
              return null // make sure we always get SOME result
            }),
          ) // this is async action. We will await it all later in code
        } else {
          allDevicesInList.push(deviceInList)
        }
      } catch (error) {
        handleDeviceStatusError()
      }
    })
    const allResolvedDevicesInList = await Promise.all(allDevicesInList)

    const removeDevicesActions = []
    logNotificationActions = []
    uniqByIdOnlineStateMessages.forEach((message, index) => {
      const { deviceId, status, inSync } = message
      try {
        const deviceInList = allResolvedDevicesInList[index]
        if (!deviceInList) {
          return // if fetch request failed, then bail out
        }
        // Fetch a device only if its not in the list already and the new status will be ONLINE.
        // When there is an event with status different than ONLINE, it means that it has to be in this list no matter what.
        // If by any change the device is not in the list when an OFFLINE status comes in, we cant risking fetching it cause it might not be present at all, since it was disowned or offboarded.
        if (status === ONLINE && (existingOnlineState[deviceId] !== status || inSync)) {
          // Add the new device to the list
          store.dispatch(addDevice(deviceInList))
        }
        if (enableNotifications) {
          handleDeviceStateNotification(message, deviceInList)
        }
        // In Local modes we have to remove any device which will get a status of OFFLINE.
        if (!OAuth.isInNormalMode && status === OFFLINE) {
          // Remove the device from the list and from the details view if active
          removeDevicesActions.push(removeDevice(deviceId))

          // Remove/Disconnect all active WebSockets which are related to this device
          WSClients.removeAllByPartialNameFromWsClient(deviceId)
        }
      } catch (error) {
        handleDeviceStatusError()
      }
    })

    const updateRegisterStateActions = []

    const existingRegisterState = getIotHubDeviceRegisterState(state)
    const uniqByIdOnlineRegisterMessages = Object.values(byIdRegistrationStateMessages).filter(
      ({ deviceId, status }) => existingRegisterState[deviceId] !== status,
    )
    uniqByIdOnlineRegisterMessages.forEach(message => {
      const { deviceId, status } = message
      try {
        updateRegisterStateActions.push(updateDeviceRegisterStatus(deviceId, status))
        // We have to remove any device which will get a status of UNREGISTERED.
        if (status === UNREGISTERED) {
          // NOTE: Registered event is now processed by OCF Cloud (to actually use device when it's ready)
          //  This means, that emit is moved within this IF block and no REGISTERED emit is done here
          SbEmitter.emit(DEVICE_REGISTER_LISTENER_NAME, { deviceId, status })

          // Remove the device from the list and from the details view if active
          removeDevicesActions.push(removeDevice(deviceId))

          // Remove/Disconnect all active WebSockets which are related to this device
          WSClients.removeAllByPartialNameFromWsClient(deviceId)
        }
        if (enableNotifications) {
          const deviceInList = deviceList.find(d => d.id === deviceId)
          handleDeviceStateNotification(message, deviceInList)
        }
      } catch (error) {
        handleDeviceStatusError()
      }
    })

    store.dispatch(
      batchActions([
        ...updateOnlineStateActions,
        ...logNotificationActions,
        ...updateRegisterStateActions,
        ...removeDevicesActions,
      ]),
    )
  },
  1000,
  { maxWait: 3000 },
)

export const devicesStatusListener = message => {
  const parsedMessage = JSON.parse(message.data)
  if ([REGISTERED, UNREGISTERED].includes(parsedMessage.status)) {
    byIdRegistrationStateMessages[parsedMessage.deviceId] = parsedMessage
  } else {
    byIdOnlineStateMessages[parsedMessage.deviceId] = {
      ...byIdOnlineStateMessages[parsedMessage.deviceId],
      ...parsedMessage,
    }
  }
  batchProcessDeviceStatus()
}
