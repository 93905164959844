import { OAuthComponentFactory } from 'skybase-oauth/oauth/oauth-component-factory'
import PropTypes from 'prop-types'
import React from 'react'

export const OAuthWrapper = ({ renderFactory, children }) =>
  renderFactory ? <OAuthComponentFactory>{children}</OAuthComponentFactory> : children

OAuthWrapper.propTypes = {
  renderFactory: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}
