import axios from 'axios'
import { showErrorToast } from '@/common/services/show-toast'

export const hasRequestTimedOut = error =>
  (error.response && error.response.status === 503) || error.code === 'ECONNABORTED'

export const handleDefaultHttpError = errorObject => {
  showErrorToast(errorObject.message)
  if (errorObject.config?.url) {
    console.error(
      'Failed request details: ',
      errorObject.config.url,
      'status: ',
      errorObject.status,
      errorObject.config,
    )
  }
}

export const handleHttpError = (title, message = '') => {
  showErrorToast(message || title, message ? title : undefined)
}

export const registerDefaultHttpErrorsHandler = dispatch => {
  axios.default.defaults.validateStatus = status => (status >= 200 && status < 300) || status === 304
  axios.interceptors.response.use(
    r => r,
    error => {
      // do not show any error when request gets canceled
      if (error.name === 'CanceledError') {
        return error
      }
      // 402 is already handled. Do not dispatch notifications
      if (!error.config || (error.response && error.response.status === 402)) {
        return Promise.reject(error)
      }
      if (error.config.customErrorHandler) {
        return error.config.customErrorHandler(error, dispatch, handleDefaultHttpError) || Promise.reject(error)
      }
      handleDefaultHttpError(error)
      return Promise.reject(error)
    },
  )
}
