import axios from 'axios'
import { v4 } from 'uuid'
import { difference } from 'lodash'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { showErrorToast } from 'skybase-ui/skybase-components/sb-toastr/actions'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { getStudioAPIHost } from '@/utils/url'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import {
  loadProjectDevice,
  loadProjectDeviceIfEmpty,
  saveProjectDevice,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { messages as t } from './backup-actions-i18n'
import { getDeviceBackupById, getDeviceBackups } from '@/fleet-configuration/data-fleet/backup/backup-selector'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { guardedDeviceFactory } from '@/fleet-configuration/data-fleet/project-devices/device-factory'
import { COMMIT_TIME_MS } from '@/fleet-configuration/components/component-committer/component-committer-constants'
import { sleep } from '@/utils'

export const SET_BACKUP_ITEMS = getActionName('SET_BACKUP_ITEMS')
export const setBackupItems = (deviceId, items) => createAction(SET_BACKUP_ITEMS, { deviceId, items })

export const UPDATE_BACKUP_ITEM = getActionName('UPDATE_BACKUP_ITEM')
export const updateBackupItem = (deviceId, backupId, updateParams) =>
  createAction(UPDATE_BACKUP_ITEM, { deviceId, backupId, updateParams })

export const REMOVE_BACKUP_ITEM = getActionName('DELETE_BACKUP_ITEM')
export const removeBackupItem = (deviceId, itemId) => createAction(REMOVE_BACKUP_ITEM, { deviceId, itemId })

export const loadDeviceBackups = piid => async dispatch => {
  const { data } = await axios.get(`${getStudioAPIHost()}/api/devices/${piid}/backups`)

  dispatch(setBackupItems(piid, data))
  return data || []
}

export const loadDeviceBackupsIfEmpty = piid => (dispatch, getState) => {
  const deviceBackups = getDeviceBackups(getState(), piid)
  if (deviceBackups) {
    return deviceBackups
  }
  return dispatch(loadDeviceBackups(piid))
}

export const createDeviceBackup = (deviceId, _) => async (dispatch, getState) => {
  await sleep(COMMIT_TIME_MS + 1) // make sure device data are processed data instead of things that are being typed in yet
  const state = getState()
  let projectDevice = getProjectDeviceById(state, deviceId)
  if (!projectDevice) {
    projectDevice = await dispatch(loadProjectDevice(deviceId))
  }
  const configuration = projectDevice.toDto()
  const device = getDeviceById(state, deviceId)
  const deviceBackup = {
    id: v4(),
    name: _(t.configurationOfDeviceDeviceNameAtCurrentTime, {
      DEVICE_NAME: device.name || deviceId,
      CURRENT_TIME: new Date().toLocaleString(),
    }),
    deviceConfiguration: configuration,
  }
  return axios
    .post(`${getStudioAPIHost()}/api/devices/${device.protocolIndependentId}/backups`, deviceBackup)
    .then(() => dispatch(loadDeviceBackups(device.protocolIndependentId)))
}

const areConfigurationsCompatible = (first, second) => {
  // did we get requested configurations?
  if (!first || !second) {
    return false
  }
  // is it same device?
  if (first.serialNumber !== second.serialNumber) {
    return false
  }
  // do controllers match? (KGate check)
  if (
    first.deviceSpecific?.controller?.parametersReadable?.snr !==
    second.deviceSpecific?.controller?.parametersReadable?.snr
  ) {
    return false
  }

  // do modules match? (KGate check)
  const firstModulesLength = first.modules?.length
  if (!firstModulesLength || firstModulesLength !== second.modules?.length) {
    return false
  }
  // do they match also by their types?
  if (
    first.modules.some((firstModule, index) => {
      const secondModule = second.modules[index]
      // here we are looking for difference in module - if this is true then configs don't match
      return (
        firstModule.types.length !== secondModule.types.length ||
        difference(firstModule.types, secondModule.types).length
      )
    })
  ) {
    return false
  }
  return true
}

export const restoreBackup = (deviceId, backupId) => async (dispatch, getState) => {
  const device = getDeviceById(getState(), deviceId)
  if (!device) {
    throw new Error("Can't restore device, that is not registered by system")
  }
  const piid = device.protocolIndependentId
  const { data } = await axios.get(`${getStudioAPIHost()}/api/devices/${piid}/backups/${backupId}`)
  await dispatch(loadProjectsIfEmpty())
  await dispatch(loadProjectDeviceIfEmpty(deviceId))
  const originalDeviceConfiguration = getProjectDeviceById(getState(), deviceId)
  if (!areConfigurationsCompatible(originalDeviceConfiguration, data?.deviceConfiguration)) {
    dispatch(showErrorToast(t.backedUpConfigurationNotCompatible, { title: t.backupRestorationFailed }))
    return false
  }
  const { deviceConfiguration, ...putData } = data
  const deviceModel = guardedDeviceFactory(dispatch, getState, { ...data.deviceConfiguration, id: deviceId })
  await dispatch(saveProjectDevice(deviceModel))
  const lastUsed = new Date().toISOString()
  // no need to wait for this request to finish
  axios.put(`${getStudioAPIHost()}/api/devices/${piid}/backups/${backupId}`, {
    ...putData,
    lastUsed,
  })
  dispatch(updateBackupItem(piid, backupId, { lastUsed }))
  const deviceBaseConfigUrl = `/fleet/configuration/devices/${deviceId}`
  if (!window.location.pathname.startsWith(deviceBaseConfigUrl)) {
    SbEmitter.emit('navigate', deviceBaseConfigUrl)
  }
  return true
}

export const renameBackup = (piid, backupId, newName) => (dispatch, getState) => {
  const backupToRename = getDeviceBackupById(getState(), piid, backupId)
  axios
    .put(`${getStudioAPIHost()}/api/devices/${piid}/backups/${backupId}`, {
      name: newName,
      lastUsed: backupToRename?.lastUsed,
    })
    .catch(err => {
      dispatch(loadDeviceBackups(piid))
      throw err
    })
}

export const deleteBackup = (piid, backupId) => async dispatch => {
  await axios.delete(`${getStudioAPIHost()}/api/devices/${piid}/backups/${backupId}`)
  dispatch(removeBackupItem(piid, backupId))
}
