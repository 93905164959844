import { combineReducers } from 'redux'

// SkyBase reducers.
import { appRootReducer } from 'skybase-ui/skybase-core/base/reducers'
import { localeReducer, STATE_KEY_NAME as localeStateKeyName } from 'skybase-ui/skybase-core/locales'
import { toastrReducer } from 'skybase-ui/skybase-components/sb-toastr'

import { notificationsReducer } from 'skybase-ui/skybase-core/notifications/notifications-reducer'
import { licensesReducer, LICENSES_STATE_KEY_NAME } from 'skybase-ui/widgets/licenses'
import { oAuthFactoryReducer, O_AUTH_STATE_KEY_NAME } from 'skybase-oauth'
import { sbDynamicTabsReducer, SB_DYNAMIC_TABS_STATE_KEY_NAME } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { sbLeftColumnReducer, SB_LEFT_COLUMN_STATE_NAME } from 'skybase-ui/skybase-components/sb-left-column'

import { modalReducer, modalsStateKeyName } from '@/common/modals/reducers'

import { iotHubReducers } from '@/iot-hub/iot-hub-reducers'
import { IOT_HUB_STATE_KEY_NAME } from '@/iot-hub/constants'
import { entriesReducer } from '@/fleet-configuration/data-fleet/reducers/entries-reducer'
import { validationReducer } from '@/fleet-configuration/validation/validation-reducer'
import { eulaReducer } from '@/common/eula/eula-reducer'
import { USER_TRACKING_KEY as userTrackingKeyName } from '@/common/user-tracking/user-tracking-constants'
import { userTrackingReducer } from '@/common/user-tracking/user-tracking-reducer'

/**
 * @description
 * Combines all reducers required by our app.
 */
export const createAppReducer = () =>
  combineReducers({
    // SkyBase reducers:
    appRoot: appRootReducer,
    notifications: notificationsReducer,
    toasts: toastrReducer,
    [O_AUTH_STATE_KEY_NAME]: oAuthFactoryReducer,
    [SB_DYNAMIC_TABS_STATE_KEY_NAME]: sbDynamicTabsReducer,
    // pages:
    [LICENSES_STATE_KEY_NAME]: licensesReducer,
    //other

    // IoT Hub reducers
    [IOT_HUB_STATE_KEY_NAME]: iotHubReducers,

    // custom:
    eula: eulaReducer,
    entries: entriesReducer,
    validation: validationReducer,
    [SB_LEFT_COLUMN_STATE_NAME]: sbLeftColumnReducer,
    [localeStateKeyName]: localeReducer,
    [modalsStateKeyName]: modalReducer,
    [userTrackingKeyName]: userTrackingReducer,
  })
